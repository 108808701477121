<template>
  <div class="product-detail">
    <v-dialog
      :value="isActiveProductDetail"
      max-width="960"
      @click:outside="closeProductDetail()"
      @keydown.esc="closeProductDetail()">
      <v-card>
        <div class="text-right">
          <v-btn
            icon
            @click="closeProductDetail()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-tabs
            v-model="isSetProduct"
            class="mb-5">
            <v-tab>
              สินค้าทั่วไป
            </v-tab>
            <v-tab>
              สินค้าแบบเซ็ต
            </v-tab>
          </v-tabs>
          <v-row
            v-if="isSetProduct === 0"
            no-gutters
            justify="space-between">
            <v-col>
              <v-text-field
                v-model="searchProduct"
                autofocus
                outlined
                dense
                hide-details
                placeholder="ค้นหา.."
                append-icon="mdi-magnify"
                class="mb-3"
                @input="onInputSearch()">
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <div class="text-right">
                สินค้าทั้งหมด: {{ totalProduct }}
              </div>
              <div class="text-right">
                <span> สถานะ: </span>
                <span v-if="preOrder">
                  สั่งจอง
                </span>
                <span v-else>
                  สั่งซื้อ
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-else
            no-gutters
            justify="space-between">
            <v-col>
              <v-text-field
                v-model="searchSetProduct"
                outlined
                dense
                hide-details
                placeholder="ค้นหา.."
                append-icon="mdi-magnify"
                class="mb-3"
                @input="onInputSearch()">
              </v-text-field>
            </v-col>
            <v-col cols="5">
              <div class="text-right">
                สินค้าทั้งหมด: {{ totalSetProduct }}
              </div>
              <div class="text-right">
                <span> สถานะ: </span>
                <span v-if="preOrder">
                  พรีออเดอร์
                </span>
                <span v-else>
                  สั่งซื้อ
                </span>
              </div>
            </v-col>
          </v-row>
          <v-data-table
            :items="dataTable"
            loader-height="2"
            :headers="headerTable"
            :loading="isLoading"
            :single-expand="true"
            show-expand
            fixed-header
            hide-default-footer>
            <template v-slot:[`item.data-image`]="{ item }">
              <img
                v-if="item.image"
                :src="item.image"
                height="40" />
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <div class="py-2 name-style">
                <span
                  :class="item.objectAvailable < 1 && !preOrder ? 'red--text' : ''">
                  {{ item.name }}
                </span>
                <div v-if="!isTypeProductDetail">
                  <div v-if="isSetProduct === 0">
                    <v-badge
                      v-if="item.subproducts.length <= 1"
                      color="primary"
                      :content="getCountSub(item.subproducts[0].id)"
                      :value="getCountSub(item.subproducts[0].id)"
                      overlap>
                      <v-btn
                        :disabled="item.objectAvailable < 1 && !preOrder"
                        class="mt-2"
                        color="primary"
                        outlined
                        small
                        @click="addProduct(item, item.subproducts[0])">
                        สินค้าหลัก
                        <span class="orange--text ml-1">
                          ({{ item.subproducts[0].objectAvailable }})
                        </span>
                      </v-btn>
                    </v-badge>
                    <div
                      v-else
                      class="d-flex flex-wrap">
                      <v-badge
                        v-for="sub in item.subproducts"
                        :key="`${sub.id}`"
                        :content="getCountSub(sub.id)"
                        :value="getCountSub(sub.id)"
                        color="primary"
                        class="mr-3"
                        overlap>
                        <v-btn
                          :disabled="sub.objectAvailable < 1 && !preOrder"
                          class="mt-2"
                          color="primary"
                          outlined
                          small
                          @click="addProduct(item, sub)">
                          {{ sub.name }}
                          <span class="orange--text ml-1"> ({{ sub.objectAvailable }}) </span>
                        </v-btn>
                      </v-badge>
                    </div>
                  </div>
                  <v-badge
                    v-else
                    color="primary"
                    class="mr-3"
                    :content="getCount(item.id)"
                    :value="getCount(item.id)"
                    overlap>
                    <v-btn
                      :disabled="item.objectAvailable < 1 && !preOrder"
                      color="primary"
                      outlined
                      small
                      @click="addSetProduct(item)">
                      สินค้าหลัก
                      <span class="orange--text ml-1"> ({{ item.objectAvailable }}) </span>
                    </v-btn>
                  </v-badge>
                </div>
              </div>
            </template>
            <template v-slot:[`item.objectAvailable`]="{ item }">
              <span :class="item.objectAvailable < 1 && !preOrder ? 'red--text' : ''">
                {{ item.objectAvailable }}
              </span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span :class="item.objectAvailable < 1 && !preOrder ? 'red--text' : ''">
                {{ item.price | formatNumber }}
              </span>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand }">
              <v-btn
                depressed
                small
                @click="expand(!isExpanded)">
                <v-icon icon>
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.data-action`]="{ item }">
              <div class="d-flex">
                <v-btn
                  color="primary"
                  outlined
                  :disabled="!item.descriptionLong"
                  @click="sendProductDetail(item)">
                  ส่งรายละเอียด
                </v-btn>
                <v-btn
                  v-if="isSetProduct === 0"
                  class="ml-2"
                  color="primary"
                  outlined
                  :disabled="!item.image"
                  @click="sendProductImage(item)">
                  ส่งรูป
                </v-btn>
              </div>
            </template>
            <!-- eslint-disable-next-line vue/no-template-shadow -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <div class="expand-description">
                  {{ item.descriptionLong ? stripHtml(item.descriptionLong) : 'ไม่มีรายละเอียด' }}
                </div>
              </td>
            </template>
          </v-data-table>
          <v-pagination
            v-if="isSetProduct === 0"
            v-model="currentPage"
            :length="isSetProduct === 0 ? productLength : setProductLength"
            :total-visible="perPage"
            class="mt-5"
            @input="onChangePaginate()">
          </v-pagination>
          <v-pagination
            v-else
            v-model="setProductPage"
            :length="isSetProduct === 0 ? productLength : setProductLength"
            :total-visible="perPage"
            class="mt-5"
            @input="onChangePaginate">
          </v-pagination>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import ProductProvider from '@/resources/ProductProvider'
import ChatProvider from '@/resources/ChatProvider'

export default {
  name: 'ProductDetail',
  props: {
    orderId: {
      type: Number,
      default: 0
    },
    isActiveProductDetail: {
      type: Boolean,
      require: true
    },
    isTypeProductDetail: {
      type: Boolean,
      require: true
    },
    dataCombine: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      expanded: [],
      headersDetail: [
        { text: '', value: 'data-image', width: '50', sortable: false },
        { text: 'รหัส', value: 'code', width: '75', sortable: false },
        { text: 'ชื่อสินค้า', value: 'name', width: '250', sortable: false },
        { text: 'คงเหลือ', value: 'objectAvailable', width: '75', sortable: false },
        { text: 'ราคา', value: 'price', width: '100', sortable: false },
        { text: '', value: 'data-action', width: '200', sortable: false }
      ],
      headersSelect: [
        { text: '', value: 'data-image', width: '50', sortable: false },
        { text: 'รหัส', value: 'code', width: '75', sortable: false },
        { text: 'ชื่อสินค้า', value: 'name', width: '250', sortable: false },
        { text: 'คงเหลือ', value: 'objectAvailable', width: '75', sortable: false },
        { text: 'ราคา', value: 'price', width: '100', sortable: false },
        { text: '', value: 'data-table-expand', width: '100', sortable: false }
      ],
      isLoading: false,
      perPage: 7,
      setProductPage: 1,
      currentPage: 1,
      isSetProduct: 0,
      searchProduct: '',
      searchSetProduct: '',
      dataProduct: [],
      dataSetProduct: [],
      totalProduct: 0,
      totalSetProduct: 0,
      productLength: 0,
      setProductLength: 0
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      stockId: 'Chat/stockId',
      preOrder: 'Chat/preOrder',
      customers: 'Chat/customers'
    }),
    customerName () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.name || ''
    },
    dataTable () {
      return this.isSetProduct === 0 ? this.dataProduct : this.dataSetProduct
    },
    headerTable () {
      return this.isTypeProductDetail ? this.headersDetail : this.headersSelect
    }
  },
  watch: {
    isActiveProductDetail () {
      this.loadAsyncProduct()
      this.loadAsyncSetProduct()
    }
  },
  methods: {
    ...mapActions({
      addPurchases: 'Chat/addPurchases',
      addSetPurchases: 'Chat/addSetPurchases',
      setSelectedProduct: 'Chat/setSelectedProduct',
      setSelectedSetProduct: 'Chat/setSelectedSetProduct',
      addNewMessage: 'Chat/addNewMessage'
    }),
    stripHtml (html) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
    getCount (id) {
      let count = 0
      this.dataCombine.forEach((i) => {
        if (i.id && i.id === id) count += parseInt(i.quantity, 0)
        else if (i.productSetId && i.productSetId === id) count += parseInt(i.quantity, 0)
      })
      return count
    },
    getCountSub (id) {
      let count = 0
      this.dataCombine.forEach((i) => {
        if (i.subproductId === id) count += parseInt(i.quantity, 0)
      })
      return count
    },
    onInputSearch: _.debounce(function () {
      this.loadSearch()
    }, 800),
    loadSearch () {
      if (this.isSetProduct === 0) {
        this.currentPage = 1
        this.loadAsyncProduct()
      } else {
        this.setProductPage = 1
        this.loadAsyncSetProduct()
      }
    },
    onChangePaginate () {
      if (this.isSetProduct === 0) {
        this.loadAsyncProduct()
      } else {
        this.loadAsyncSetProduct()
      }
    },
    closeProductDetail () {
      this.$emit('update:isActiveProductDetail', false)
    },
    sendProductDetail (item) {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      const reply = {
        id: uuidv4(),
        for: customerId,
        result: {
          message: this.stripHtml(item.descriptionLong),
          createdTime: this.$dayjs().format()
        },
        status: 'sending'
      }
      this.addNewMessage(reply)
      ChatProvider.sendMessage({
        pageId,
        customerId
      }, {
        message: reply.result.message,
        customerName: this.customerName,
        customer: customerId,
        tid: reply.id
      }).then((res) => {
        this.addNewMessage({
          ...reply,
          mid: res.mid,
          error: res.error,
          status: res.status === 400 ? 'failed' : 'read'
        })
      })
      this.closeProductDetail()
    },
    sendProductImage (item) {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      const reply = {
        id: uuidv4(),
        for: customerId,
        result: {
          message: '', // คุณส่งรูปภาพ
          base64: item.image,
          createdTime: this.$dayjs().format()
        },
        status: 'sending'
      }
      this.addNewMessage(reply)
      ChatProvider.sendFile({
        pageId,
        customerId
      }, {
        fileUrl: item.image,
        customerName: this.customerName,
        customer: customerId,
        tid: reply.id
      }).then((res) => {
          this.addNewMessage({
            ...reply,
            mid: res.mid,
            error: res.error,
            status: res.status === 400 ? 'failed' : 'read'
          })
      })
      this.closeProductDetail()
    },
    addProduct (item, sub) {
      let fullName = item.name

      if (sub.name !== 'main') {
        fullName = `${item.name } (${sub.name})`
      }

      const selectedProduct = {
        thumb: item.imageThumb,
        name: fullName,
        price: Number(sub.objectPrice).toFixed(2),
        quantity: 1,
        subproductId: sub.id,
        objectAvailable: sub.objectAvailable
      }
      const product = this.dataCombine.find((i) => i.subproductId === sub.id)

      if (this.preOrder || !product || (product && product.quantity < sub.objectAvailable)) {
        if (this.orderId) {
          this.addPurchases({
            id: this.orderId,
            product: selectedProduct
          })
        } else {
          this.setSelectedProduct(selectedProduct)
        }
      }
    },
    addSetProduct (item) {
      const selectedSetProduct = {
        thumb: item.imageThumb,
        name: item.name,
        price: Number(item.price).toFixed(2),
        quantity: 1,
        productSetId: item.id,
        objectAvailable: item.objectAvailable
      }
      const setProduct = this.dataCombine.find((i) => i.productSetId === item.id)
      if (this.preOrder || !setProduct || (setProduct && setProduct.quantity < item.objectAvailable)) {
        if (this.orderId) {
          this.addSetPurchases({
            id: this.orderId,
            product: selectedSetProduct
          })
        } else {
          // console.log(selectedSetProduct)
          this.setSelectedSetProduct(selectedSetProduct)
        }
      }
    },
    async loadAsyncSetProduct () {
      const pageId = this.pageId
      this.dataSetProduct = []
      this.isLoading = true
      const response = await ProductProvider.getProducts({ pageId }, {
        page: this.setProductPage,
        search: this.searchSetProduct,
        stockSelection: this.stockId
      }).finally(() => {
        this.isLoading = false
      })
      this.dataSetProduct = response.setResults || []
      this.totalSetProduct = response.totalSetResults || 0
      this.setProductLength = Math.ceil(this.totalSetProduct / this.perPage)
    },
    async loadAsyncProduct () {
      const pageId = this.pageId
      this.dataProduct = []
      this.isLoading = true
      const response = await ProductProvider.getProducts({ pageId }, {
        page: this.currentPage,
        search: this.searchProduct,
        stockSelection: this.stockId
      }).finally(() => {
        this.isLoading = false
      })
      this.dataProduct = response.results
      this.totalProduct = response.totalResults
      this.productLength = Math.ceil(this.totalProduct / this.perPage)
    }
  }
}
</script>

<style>
  .product-detail .v-tab {
    min-width: 90px !important;
  }
  .expand-description {
    padding: 8px 4px
    /*  */
  }
</style>

<style scropeed>
  .name-style {
    height: auto;
    overflow-y: none;
  }

  @media (max-width: 599px){
    .name-style {
      padding-left: 20px;
      padding-right: 10px;
      height: 80px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <div
    class="chat-sender">
    <div
      class="chat-room-footer px-2 py-2"
      style="position: relative">
      <v-textarea
        v-model="message"
        placeholder="พิมพ์ข้อความของคุณ..."
        hide-details
        no-resize
        autofocus
        filled
        rows="3"
        @keydown.enter.exact.prevent
        @keyup.enter.exact="sendReply">
      </v-textarea>
      <div class="mt-2 d-flex justify-end gap-2">
        <v-btn
          color="primary"
          outlined
          @click="isActiveProductDetail = true">
          <span class="d-sm-inline d-none">รายละเอียด</span>สินค้า
        </v-btn>
        <v-btn
          class="btn-linkcode"
          color="primary"
          outlined
          @click="isLinkRewardMessage = true">
          <span class="d-sm-inline d-none">ลิ้งค์เก็บ</span>โค้ด
        </v-btn>
        <broadcast-message />
        <v-btn
          class="btn-quickmessage"
          color="primary"
          outlined
          @click="isQuickReplyMessage = true">
          <img
            :src="messageButton"
            alt="messageButton"
            style="width: 20px; height: 20px;">
        </v-btn>
        <v-file-input
          id="file"
          v-model="uploadFile"
          class="btn-upload"
          prepend-icon="mdi-image"
          prepend-icon-color="#1ba7e1"
          hide-input
          @change="readFile($event)">
        </v-file-input>
        <v-divider
          class="mx-2"
          vertical>
        </v-divider>
        <v-btn
          class="btn-sendmessage"
          color="primary"
          depressed
          rounded
          @click="sendReply">
          <div>
            <v-icon
              class="icon-send-rotate"
              small>
              mdi-send
            </v-icon>
            <span class="d-sm-inline d-none ml-2">ส่งข้อความ</span>
          </div>
        </v-btn>
      </div>
      <product-detail
        :is-active-product-detail.sync="isActiveProductDetail"
        :is-type-product-detail="isTypeProductDetail"
        :message.sync="message">
      </product-detail>
      <quick-reply-message
        :is-quick-reply-message.sync="isQuickReplyMessage"
        :message.sync="message">
      </quick-reply-message>
      <link-reward-message
        :is-link-reward-message.sync="isLinkRewardMessage"
        :message.sync="message">
      </link-reward-message>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ProductDetail from '@/views/inbox/pages/message/components/ProductDetail.vue'
import QuickReplyMessage from '@/views/inbox/pages/message/components/QuickReplyMessage.vue'
import LinkRewardMessage from '@/views/inbox/pages/message/components/LinkRewardMessage.vue'
import ChatProvider from '@/resources/ChatProvider'
import BroadcastMessage from './BroadcastMessage.vue'

export default {
  name: 'ChatSender',
  components: {
    ProductDetail,
    QuickReplyMessage,
    LinkRewardMessage,
    BroadcastMessage
  },
  data () {
    return {
      message: '',
      uploadFile: null,
      isActiveProductDetail: false,
      isLinkRewardMessage: false,
      isTypeProductDetail: true,
      isQuickReplyMessage: false,
      messageButton: require('@/assets/svg/ChatIcon/MessageButton.svg'),
      pictureButton: require('@/assets/svg/ChatIcon/PictureButton.svg')
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      customers: 'Chat/customers'
    }),
    customerName () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.name || ''
    }
  },
  methods: {
    ...mapActions({
      addNewMessage: 'Chat/addNewMessage'
    }),
    readFile (file) {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      const reader = new FileReader()
      reader.addEventListener('load', () => this.upload(pageId, customerId, reader, file), false)

      if (file) {
        reader.readAsDataURL(file)
      }
    },
    async upload (pageId, customerId, reader, file) {
      const base64 = reader.result
      const reply = {
        id: uuidv4(),
        for: customerId,
        result: {
          message: '', // คุณส่งรูปภาพ
          base64,
          createdTime: this.$dayjs().format()
        },
        status: 'sending'
      }
      this.addNewMessage(reply)
      ChatProvider.sendFile({
        pageId,
        customerId
      }, {
        file,
        customerName: this.customerName,
        customer: customerId,
        tid: reply.id
      }).then((res) => {
          this.addNewMessage({
            ...reply,
            mid: res.mid,
            error: res.error,
            status: res.status === 400 ? 'failed' : 'read'
          })
      })
    },
    sendReply () {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      if (this.message) {
        const reply = {
          id: uuidv4(),
          for: customerId,
          result: {
            message: this.message,
            createdTime: this.$dayjs().format()
          },
          status: 'sending'
        }
        this.addNewMessage(reply)
        ChatProvider.sendMessage({
          pageId,
          customerId
        }, {
          message: this.message,
          customerName: this.customerName,
          customer: customerId,
          tid: reply.id
        })
        .then((res) => {
          this.addNewMessage({
            ...reply,
            mid: res.mid,
            error: res.error,
            status: res.status === 400 ? 'failed' : 'read'
          })
        })
        this.message = ''
      }
    }
  }
}

</script>

<style>
  .v-icon.v-icon.v-icon--link {
    color: #1976d2 !important;
  }

  div.v-application--is-ltr .v-input__prepend-outer {
    margin-right: 9px;
    margin-left: 9px;
  }

  .btn-sendmessage.v-btn:not(.v-btn--round).v-size--default,
  .btn-linkcode.v-btn:not(.v-btn--round).v-size--default,
  .btn-quickmessage.v-btn:not(.v-btn--round).v-size--default {
    min-width: 32px !important;
    padding: 0 10px;
  }

  .btn-upload {
    flex: none;
    justify-content: center;
    border: 1px solid #1ba7e1;
    border-radius: 5px;
    margin-top: 0;
    padding-top: 0;
  }

  .btn-upload .v-icon.v-icon.v-icon--link {
    color: #1ba7e1 !important;
  }

  .btn-upload .v-input__prepend-outer {
      margin-bottom: 5px;
      margin-top: 5px;
  }
</style>

<style scoped>
  .chat-room-footer {
    background-color: #ffffff;
  }
  .icon-send-rotate {
    margin-top: -5px;
    transform: rotate(-45deg);
  }
  .sending {
    position: absolute;
    width: 100%;
    top: -35px;
    left: 0;
    background: #d6d6d6;
    padding: 6px;
  }
</style>

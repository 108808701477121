<template>
  <div class="payment-split-bank">
    <div
      class="order-main"
      :style="{'height': containerHeight, 'padding-bottom': paddingBottom}"
      @scroll="onScroll($event)">
      <div class="d-flex justify-space-between align-center">
        <v-pagination
          v-model="page"
          :length="orderPayments.length"
          :total-visible="5"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right">
        </v-pagination>
        <modal
          v-bind="confirmDialog"
          @onConfirm="onDialogConfirm()"
          @onCancel="onDialogCancel()" />
        <v-btn
          color="primary"
          rounded
          depressed
          small
          @click="addOrderPayment()">
          <v-icon
            class="mr-1"
            small>
            mdi-plus-circle
          </v-icon>
          รายการ
        </v-btn>
      </div>
      <div
        v-if="price > totalPayment"
        class="my-3">
        <span class="red--text"> ยอดค้างชำระ {{ arrears }} บาท </span>
      </div>
      <div v-if="orderPayment._destroy === 0">
        <div class="mt-2">
          <div class="d-flex justify-space-between align-end">
            <div class="d-flex align-center">
              <label class="text--book">
                หลักฐานการโอนเงิน
                <!-- {{ page }} -->
              </label>
              <v-btn
                v-if="!orderPayment.paymentAccepted"
                icon
                @click="removeOrderPayment(orderPayment, index)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
              <span
                v-if="orderPayment.id && !orderPayment.paymentAccepted"
                class="red--text">
                (รอตรวจสอบ)
              </span>
            </div>
            <v-btn
              v-if="orderPayment.id && !orderPayment.paymentAccepted && !orderPayment.canEdit"
              color="primary"
              small
              depressed
              rounded
              @click="activeEditPayment(index, true)">
              แก้ไข
            </v-btn>
            <v-btn
              v-if="orderPayment.id && !orderPayment.paymentAccepted && orderPayment.canEdit"
              color="primary"
              small
              depressed
              rounded
              @click="activeEditPayment(index, false)">
              ตกลง
            </v-btn>
          </div>
          <div
            v-if="orderPayment.paymentSlip || orderPayment.paymentSlipUrl"
            class="text-center mt-2">
            <slip-auto-verify
              :src="orderPayment.paymentSlip || orderPayment.paymentSlipUrl"
              :items="bankAccounts"
              :active="!orderPayment.paymentAccepted && orderPayment.canEdit ? true : false"
              @verify="slipVerify($event, index)" />
          </div>
          <v-text-field
            v-else-if="orderPayment.paymentAccepted"
            hide-details
            disabled
            filled
            dense>
          </v-text-field>
          <p
            v-else
            class="primary--text mt-3">
            กรุณาเลือกรูปภาพผ่านแชท
          </p>
          <div class="mt-2">
            <label class="text--book"> วันที่โอน </label>
            <v-dialog
              ref="dialog"
              v-model="datePickerModal"
              width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="orderPayment.transferredDateField"
                  :disabled="orderPayment.paymentAccepted || !orderPayment.canEdit ? true : false"
                  :outlined="!orderPayment.paymentAccepted ? true : false"
                  :filled="orderPayment.paymentAccepted ? true : false"
                  prepend-inner-icon="mdi-calendar"
                  class="mt-2"
                  v-bind="attrs"
                  hide-details
                  dense
                  v-on="on">
                </v-text-field>
              </template>
              <v-date-picker
                v-model="orderPayment.transferredDateField"
                full-width>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  color="error"
                  @click="datePickerModal = false">
                  ยกเลิก
                </v-btn>
                <v-btn
                  depressed
                  color="primary"
                  @click="datePickerModal = false">
                  ตกลง
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </div>
          <div class="mt-2">
            <label class="text--book"> เวลาที่โอน </label>
            <v-text-field
              v-model="orderPayment.transferredTimeField"
              :disabled="orderPayment.paymentAccepted || !orderPayment.canEdit ? true : false"
              :outlined="!orderPayment.paymentAccepted ? true : false"
              :filled="orderPayment.paymentAccepted ? true : false"
              type="time"
              prepend-inner-icon="mdi-clock"
              class="mt-2"
              hide-details
              dense>
            </v-text-field>
          </div>
          <div class="mt-2">
            <label class="text--book"> ธนาคารของร้านค้า </label>
            <v-select
              v-model="orderPayment.bankAccountId"
              :items="displayingBankAccounts"
              :disabled="orderPayment.paymentAccepted || !orderPayment.canEdit ? true : false"
              :outlined="!orderPayment.paymentAccepted ? true : false"
              :filled="orderPayment.paymentAccepted ? true : false"
              item-text="accountName"
              item-value="id"
              class="mt-2"
              dense
              hide-details>
            </v-select>
          </div>
          <div class="mt-2">
            <label class="text--book"> ยอดเงิน </label>
            <v-text-field
              v-model="orderPayment.transferNote"
              :disabled="orderPayment.paymentAccepted || !orderPayment.canEdit ? true : false"
              :outlined="!orderPayment.paymentAccepted ? true : false"
              :filled="orderPayment.paymentAccepted ? true : false"
              placeholder="ยอดเงิน"
              class="mt-2"
              dense
              hide-details>
            </v-text-field>
          </div>
        </div>
      </div>
    </div>
    <div class="order-action">
      <v-btn
        v-if="checkAllPaymentAccepted"
        color="primary"
        depressed
        rounded
        @click="onSavePayment(pageId)">
        บันทึก
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderProvider from '@/resources/OrderProvider'
import SlipAutoVerify from './SlipAutoVerify.vue'

export default {
  name: 'PaymentSplitBank',
  components: { SlipAutoVerify },
  props: {
    orderPayments: {
      type: Array,
      default: () => []
    },
    displayingBankAccounts: {
      type: Array,
      default: () => []
    },
    price: {
      type: Number,
      default: 0
    },
    loadAsyncOrder: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      page: 1,
      scrollTop: 0,
      currentForm: 0,
      isSubmitting: false,
      confirmDialog: {
        confirmType: null,
        confirmOnly: false,
        title: 'ลบข้อมูล',
        message: 'ยืนยันลบข้อมูลนี้หรือไม่',
        confirmText: 'ตกลง',
        cancelText: 'ยกเลิก',
        active: false,
        value: null
      },
      currentIndex: 0,
      currentData: {},
      datePickerModal: false,
      timePickerModal: false
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      shopInfo: 'Chat/shopInfo',
      orderId: 'Chat/orderId',
      getOrder: 'Chat/getOrder',
      isloading: 'Notification/isLoading'
    }),
    index () {
      return this.page - 1
    },
    orderPayment () {
      return this.orderPayments[this.index] || {}
    },
    bankAccounts () {
      return this.shopInfo.bankAccounts ? this.shopInfo.bankAccounts : []
    },
    banks () {
      return this.shopInfo.shop ? this.shopInfo.shop.banksWithName : []
    },
    totalPayment () {
      let total = 0
      for (let i = 0; i < this.orderPayments.length; i++) {
        total += Number(this.orderPayments[i].transferNote)
      }
      return total
    },
    arrears () {
      return this.price - this.totalPayment
    },
    checkAllPaymentAccepted () {
      const payment = this.orderPayments.find((item) => !item.paymentAccepted)
      if (payment) {
        return true
      }
      return false
    },
    containerHeight () {
      return `calc(100vh - ${ 343 - this.scrollTop }px)`
    },
    paddingBottom () {
      return this.scrollTop < 60 ? `${this.scrollTop}px` : '60px'
    },
    slip () {
      return this.imageSlip || this.imageUpload
    }
  },
  watch: {
    page (val) {
      this.currentForm = val - 1
      this.setOrderPaymentIndex(val - 1)
    }
  },
  mounted () {
    this.page = this.orderPayments.length
  },
  methods: {
    ...mapActions({
      setOrderPayment: 'Chat/setOrderPayment',
      setOrderPaymentIndex: 'Chat/setOrderPaymentIndex',
      deleteOrderPayment: 'Chat/deleteOrderPayment',
      setEditPayment: 'Chat/setEditPayment',
      showErrorMessage: 'Notification/showErrorMessage',
      closeErrorMessage: 'Notification/closeErrorMessage',
      openLoading: 'Notification/openLoading',
      closeLoading: 'Notification/closeLoading'
    }),
    slipVerify (value, index) {
      this.orderPayments[index].bankAccountId = value.bankAccountId
      this.orderPayments[index].transferredDateField = this.$dayjs(value.transferredAt).format('YYYY-MM-DD')
      this.orderPayments[index].transferredTimeField = this.$dayjs(value.transferredAt).format('HH:mm')
      this.orderPayments[index].transferNote = value.amount
    },
    onScroll (event) {
      const element = event.currentTarget || event.target
      this.scrollTop = element.scrollTop
      this.$emit('scroll', event)
    },
    onDialogConfirm () {
      const pageId = this.pageId
      this.deleteOrderPayment({
        id: this.orderId,
        index: this.currentIndex
      })
      if (this.currentData && this.currentData.id) {
        this.onSavePayment(pageId)
      }
      this.page = this.orderPayments.length
      this.confirmDialog.active = false
    },
    onDialogCancel () {
      this.confirmDialog.active = false
    },
    // currentPosition (index) {
    //   this.currentForm = index
    //   this.setOrderPaymentIndex(index)
    // },
    addOrderPayment () {
      const payment = {
        id: null,
        orderId: this.orderId,
        transferredDateField: this.$dayjs().format('YYYY-MM-DD'),
        transferredTimeField: this.$dayjs().format('HH:mm'),
        bankAccountId: this.displayingBankAccounts[0].id,
        transferNote: '',
        paymentSlip: '',
        _destroy: 0,
        canEdit: true
      }
      this.setOrderPayment({ id: this.orderId, payment })
      this.setOrderPaymentIndex(this.orderPayments.length - 1)
      this.currentForm = this.orderPayments.length - 1
      this.page = this.orderPayments.length
    },
    removeOrderPayment (item, index) {
      this.confirmDialog.active = true
      this.currentIndex = index
      this.currentData = item
    },
    activeEditPayment (index, state) {
      this.setEditPayment({
        id: this.orderId,
        index,
        state
      })
    },
    async onSavePayment (pageId) {
      if (!this.isSubmitting) {
        this.isSubmitting = true
        this.openLoading()

        const payload = {
          orderId: this.orderId,
          orderPayments: this.orderPayments.map((payment) => ({
            bank: this.bankAccounts.find((x) => x.id === payment.bankAccountId).bank,
            transferredAt: `${payment.transferredDateField} ${payment.transferredTimeField}`,
            id: payment.id,
            amount: payment.transferNote,
            remotePaymentSlipUrl: payment.paymentSlip,
            orderId: this.orderId,
            bankAccountId: payment.bankAccountId,
            _destroy: payment._destroy
          }))
        }

        await OrderProvider.submitPayment({ pageId }, payload)
        this.showErrorMessage({
          type: 'success',
          message: 'บันทึกการชำระเงินเรียบร้อยแล้ว'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 5000)
        this.loadAsyncOrder()
        this.isSubmitting = false
        this.closeLoading()
      }
    },
    updatePayment (name, val) {
      this.$emit(`update:${name}`, val)
    }
  }
}
</script>

<style>
  .payment-split-bank .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
  }

  .payment-split-bank .v-select__selection--comma {
    color: #6c6c6c !important;
  }

  .payment-split-bank .v-pagination__navigation {
    width: 26px;
    height: 26px;
  }

  .payment-split-bank .v-pagination__item {
    min-width: 26px;
    height: 26px;
  }
</style>

<style scoped>
  hr {
    background-color: #f2f2f2;
    height: 1px;
    border: 0;
    margin: 40px 0px 0px 0px;
  }
</style>

<template>
  <div class="product-selection">
    <div class="mb-4">
      <v-btn
        class="px-0 mr-2 v-btn--active"
        color="primary"
        :ripple="false"
        rounded
        plain
        @click="$emit('click:close')">
        <v-icon left>
          mdi-chevron-left
        </v-icon>
        กลับ
      </v-btn>
      <v-chip
        :color="colorChipGeneral"
        class="mx-1 px-4"
        @click="setType('general')">
        สินค้าทั่วไป
      </v-chip>
      <v-chip
        :color="colorChipSet"
        class="mx-1 px-4"
        @click="setType('set')">
        สินค้าเซ็ต
      </v-chip>
    </div>
    <div class="mb-4">
      <v-text-field
        :value="search"
        placeholder="ค้นหาสินค้า"
        prepend-inner-icon="mdi-magnify"
        autocomplete="off"
        background-color="#ffffff"
        outlined
        dense
        hide-details
        @input="debounceSearching($event)" />
    </div>
    <div class="product-items-wrap">
      <component
        :is="productSelectionComponent"
        :search="search"
        :page-id="pageId"
        :stock-id="stockId"
        :order-id="orderId"
        :pre-order="preOrder"
        :selected="selected"
        @click:senddetail="sendDetail($event)"
        @click:sendimage="sendImage($event)" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { mapActions, mapGetters } from 'vuex'
import ChatProvider from '@/resources/ChatProvider'
import ProductGeneralSelection from './ProductGeneralSelection.vue'
import ProductSetSelection from './ProductSetSelection.vue'

export default {
  components: { ProductGeneralSelection, ProductSetSelection },
  props: {
    orderId: {
      type: Number,
      default: 0
    },
    selectedOrder: {
      type: Object,
      default: () => ({
        purchases: [],
        setPurchases: []
      })
    }
  },
  data () {
    return {
      type: 'general',
      search: ''
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      stockId: 'Chat/stockId',
      preOrder: 'Chat/preOrder',
      customers: 'Chat/customers'
    }),
    customerId () {
      return this.$route.params.customerId
    },
    customerName () {
      const customers = _.cloneDeep(this.customers)
      const customer = customers[this.customerId] || {}
      return customer.name || ''
    },
    colorChipGeneral () {
      return this.type === 'general' ? 'primary' : 'default'
    },
    colorChipSet () {
      return this.type === 'set' ? 'primary' : 'default'
    },
    productSelectionComponent () {
      return this.type === 'general' ? 'product-general-selection' : 'product-set-selection'
    },
    selected () {
      if (this.type === 'general') {
        return this.selectedOrder?.purchases || []
      }
      if (this.type === 'set') {
        return this.selectedOrder?.setPurchases || []
      }
      return []
    }
  },
  created () {
    this.debounceSearching = _.debounce(this.setSearch, 800)
  },
  methods: {
    ...mapActions({
      addNewMessage: 'Chat/addNewMessage'
    }),
    sendDetail (msg) {
      const pageId = this.pageId
      const customerId = this.customerId
      if (!pageId) {
        console.error('No page')
        return
      }
      if (!customerId) {
        console.error('No customer')
        return
      }
      if (!msg) {
        console.error('No msg')
        return
      }
      const reply = {
        id: uuidv4(),
        for: customerId,
        result: {
          message: msg,
          createdTime: this.$dayjs().format()
        },
        status: 'sending'
      }
      this.addNewMessage(reply)
      ChatProvider.sendMessage({
        pageId,
        customerId
      }, {
        message: reply.result.message,
        customerName: this.customerName,
        customer: customerId,
        tid: reply.id
      }).then((res) => {
        this.addNewMessage({
          ...reply,
          mid: res.mid,
          error: res.error,
          status: res.status === 400 ? 'failed' : 'read'
        })
      })
    },
    sendImage (img) {
      const pageId = this.pageId
      const customerId = this.customerId
      if (!pageId) {
        console.error('No page')
        return
      }
      if (!customerId) {
        console.error('No customer')
        return
      }
      if (!img) {
        console.error('No img')
        return
      }
      const reply = {
        id: uuidv4(),
        for: customerId,
        result: {
          message: '',
          base64: img,
          createdTime: this.$dayjs().format()
        },
        status: 'sending'
      }
      this.addNewMessage(reply)
      ChatProvider.sendFile({
        pageId,
        customerId
      }, {
        fileUrl: img,
        customerName: this.customerName,
        customer: customerId,
        tid: reply.id
      }).then((res) => {
        this.addNewMessage({
          ...reply,
          mid: res.mid,
          error: res.error,
          status: res.status === 400 ? 'failed' : 'read'
        })
      })
    },
    setSearch (search = '') {
      this.search = search
    },
    setType (type = 'general') {
      this.type = type
    }
  }
}
</script>

<style scoped>
  .product-selection {
    height: calc(100vh - 80px)
  }
  .product-items-wrap {
    overflow-x: hidden;
    height: calc(100% - 120px);
  }
</style>

<template>
  <div class="customer-info">
    <v-form
      ref="form"
      v-model="validForm">
      <div
        class="order-main"
        :style="{'height': containerHeight, 'padding-bottom': paddingBottom}"
        @scroll="onScroll($event)">
        <div>
          <label class="text--book">
            ชื่อ
            <span class="red--text"> * </span>
          </label>
          <v-text-field
            :value="customerName"
            :rules="nameRules"
            class="mt-2"
            placeholder="ชื่อลูกค้า..."
            outlined
            dense
            required
            @input="updateForm('customerName', $event)">
          </v-text-field>
        </div>
        <div>
          <label class="text--book">
            ที่อยู่
            <span class="red--text"> * </span>
          </label>
          <v-textarea
            v-model="formData.customerAddress"
            :rules="addressRules"
            class="my-2"
            placeholder="ที่อยู่ลูกค้า..."
            rows="4"
            outlined
            no-resize
            dense
            required
            hide-details
            @input="updateForm('customerAddress', $event)">
          </v-textarea>
          <span
            v-if="addr1"
            class="recommend-address-text"
            v-html="addr1Html">
          </span>&nbsp;
          <a
            v-if="addr1"
            class="btn-apply"
            @click="replaceAddress(1)">ปรับใช้</a>
          <br v-if="addr2" />
          <span
            v-if="addr2"
            class="recommend-address-text"
            v-html="addr2Html">
          </span>&nbsp;
          <a
            v-if="addr2"
            class="btn-apply"
            @click="replaceAddress(2)">ปรับใช้</a>
          <br v-if="addr3" />
          <span
            v-if="addr3"
            class="recommend-address-text"
            v-html="addr3Html">
          </span>&nbsp;
          <a
            v-if="addr3"
            class="btn-apply"
            @click="replaceAddress(3)">ปรับใช้</a>
        </div>
        <div>
          <label class="text--book">
            รหัสไปรษณีย์
            <span class="red--text"> * </span>
          </label>
          <v-text-field
            v-model="formData.customerPostcode"
            :rules="postcodeRules"
            :onkeypress="onlyKeyNumber"
            type="tel"
            class="mt-2"
            placeholder="รหัสไปรษณีย์ลูกค้า..."
            maxlength="5"
            outlined
            dense
            required
            @input="updateForm('customerPostcode', $event)">
          </v-text-field>
        </div>
        <div>
          <label class="text--book">
            เบอร์โทรศัพท์
            <span class="red--text"> * </span>
          </label>
          <v-text-field
            :value="customerTel"
            :rules="telephoneRules"
            :onkeypress="onlyKeyNumber"
            type="tel"
            class="mt-2"
            placeholder="เบอร์โทรศัพท์ลูกค้า..."
            outlined
            dense
            required
            @input="updateForm('customerTel', $event)">
          </v-text-field>
        </div>
        <div>
          <label class="text--book"> อีเมล </label>
          <v-text-field
            :value="customerEmail"
            class="mt-2"
            placeholder="อีเมลลูกค้า"
            outlined
            dense
            @input="updateForm('customerEmail', $event)">
          </v-text-field>
        </div>
        <div>
          <label class="text--book"> เลขประจำตัวผู้เสียภาษี </label>
          <v-text-field
            :value="customerTaxId"
            class="mt-2"
            placeholder="เลขประจำตัวผู้เสียภาษีลูกค้า"
            outlined
            dense
            @input="updateForm('customerTaxId', $event)">
          </v-text-field>
        </div>
      </div>
      <div class="order-action">
        <v-btn
          color="primary"
          depressed
          rounded
          @click="validateCustomerInfo()">
          <img
            :src="saveButton"
            alt="saveButton"
            class="mr-2">
          บันทึก
        </v-btn>
      </div>
    </v-form>
    <v-overlay
      :absolute="true"
      :opacity="0.08"
      :value="isLoading">
      <v-progress-circular
        :indeterminate="isLoading"
        color="primary">
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import addreszer from '@/assets/js/addreszer'

export default {
  name: 'CustomerInfo',
  props: {
    customerName: {
      type: String,
      default: ''
    },
    customerAddress: {
      type: String,
      default: ''
    },
    customerPostcode: {
      type: String,
      default: ''
    },
    customerTel: {
      type: String,
      default: ''
    },
    customerEmail: {
      type: String,
      default: ''
    },
    customerTaxId: {
      type: String,
      default: ''
    },
    submitOrder: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      scrollTop: 0,
      validForm: false,
      onlyKeyNumber: 'return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)',
      nameRules: [
        (v) => !!v || 'กรุณากรอกชื่อ'
      ],
      addressRules: [
        (v) => !!v || 'กรุณากรอกที่อยู่'
      ],
      postcodeRules: [
        (v) => !!v || 'กรุณากรอกรหัสไปรษณีย์'
      ],
      telephoneRules: [
        (v) => !!v || 'กรุณากรอกเบอร์โทรศัพท์'
      ],
      formData: {
        customerAddress: this.customerAddress,
        customerPostcode: this.customerPostcode
      },
      saveButton: require('@/assets/svg/IconButton/SaveButton.svg'),
      addr1: '',
      addr1Html: '',
      zipcode1: '',
      addr2: '',
      addr2Html: '',
      zipcode2: '',
      addr3: '',
      addr3Html: '',
      zipcode3: ''
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'Notification/isLoading'
    }),
    containerHeight () {
      return `calc(100vh - ${ 343 - this.scrollTop }px)`
    },
    paddingBottom () {
      return this.scrollTop < 60 ? `${this.scrollTop}px` : '60px'
    }
  },
  watch: {
    customerTel (val) {
      const splitText = val.split(/\W+/)
      let newText = ''

      for (let i = 0; i < splitText.length; i++) {
        newText += splitText[i]
      }

      this.$emit('update:customerTel', newText)
    }
  },
  mounted () {

  },
  methods: {
    ...mapActions({
      openLoading: 'Notification/openLoading',
      closeLoading: 'Notification/closeLoading'
    }),
    replaceAddress (pos) {
      if (pos === 1) {
        this.formData.customerAddress = this.addr1
        this.formData.customerPostcode = this.zipcode1
      } else if (pos === 2) {
        this.formData.customerAddress = this.addr2
        this.formData.customerPostcode = this.zipcode2
      } else if (pos === 3) {
        this.formData.customerAddress = this.addr3
        this.formData.customerPostcode = this.zipcode3
      }
      this.addr1 = ''
      this.addr1Html = ''
      this.zipcode1 = ''
      this.addr2 = ''
      this.addr2Html = ''
      this.zipcode2 = ''
      this.addr3 = ''
      this.addr3Html = ''
      this.zipcode3 = ''
    },
    // eslint-disable-next-line func-names
    suggestAddress: _.debounce(function (e) {
      const context = e
      if (context.length > 10) {
        const addr1 = addreszer(context, 0.3, 0.65)

        if (addr1 && addr1.data) {
          const address = `<span class="book--text">${addr1.address}</span>`
          const district = addr1.correct.includes(addr1.data.district) ? `แขวง${addr1.data.district}` : `<span class="red--text">แขวง${addr1.data.district}</span>`
          const amphoe = addr1.correct.includes(addr1.data.amphoe) ? `เขต${addr1.data.amphoe}` : `<span class="red--text">เขต${addr1.data.amphoe}</span>`
          const province = addr1.correct.includes(addr1.data.province) ? `จังหวัด${addr1.data.province}` : `<span class="red--text">จังหวัด${addr1.data.province}</span>`
          const zipcode = addr1.correct.includes(addr1.data.zipcode) ? `${addr1.data.zipcode}` : `<span class="red--text">${addr1.data.zipcode}</span>`
          const note = addr1.data.note.length === 0 ? '' : `<span class="grey--text">(${addr1.data.note})</span>`

          this.addr1Html = `<span>${address} ${district} ${amphoe} ${province} ${zipcode} ${note}</span>`
          this.addr1 = `${addr1.address} แขวง${addr1.data.district} เขต${addr1.data.amphoe} จังหวัด${addr1.data.province} ${addr1.data.zipcode}`
          this.zipcode1 = addr1.data.zipcode
        } else {
          this.addr1 = ''
          this.addr1Html = ''
          this.zipcode1 = ''
        }

        const addr2 = addreszer(context, 0.3, 0.5)
        if (addr2 && addr2.data && addr2.data.district_code !== addr1.data.district_code) {
          const address = `<span class="book--text">${addr2.address}</span>`
          const district = addr2.correct.includes(addr2.data.district) ? `แขวง${addr2.data.district}` : `<span class="red--text">แขวง${addr2.data.district}</span>`
          const amphoe = addr2.correct.includes(addr2.data.amphoe) ? `เขต${addr2.data.amphoe}` : `<span class="red--text">เขต${addr2.data.amphoe}</span>`
          const province = addr2.correct.includes(addr2.data.province) ? `จังหวัด${addr2.data.province}` : `<span class="red--text">จังหวัด${addr2.data.province}</span>`
          const zipcode = addr2.correct.includes(addr2.data.zipcode) ? `${addr2.data.zipcode}` : `<span class="red--text">${addr2.data.zipcode}</span>`
          const note = addr2.data.note.length === 0 ? '' : `<span class="grey--text">(${addr2.data.note})</span>`

          this.addr2Html = `<span>${address} ${district} ${amphoe} ${province} ${zipcode} ${note}</span>`
          this.addr2 = `${addr2.address} แขวง${addr2.data.district} เขต${addr2.data.amphoe} จังหวัด${addr2.data.province} ${addr2.data.zipcode}`
          this.zipcode2 = addr2.data.zipcode
        } else {
          this.addr2 = ''
          this.addr2Html = ''
          this.zipcode2 = ''
        }

        const addr3 = addreszer(context, 0.3, 0.1)
        if (addr3 && addr3.data && addr3.data.district_code !== addr2.data.district_code) {
          const address = `<span class="book--text">${addr3.address}</span>`
          const district = addr3.correct.includes(addr3.data.district) ? `แขวง${addr3.data.district}` : `<span class="red--text">แขวง${addr3.data.district}</span>`
          const amphoe = addr3.correct.includes(addr3.data.amphoe) ? `เขต${addr3.data.amphoe}` : `<span class="red--text">เขต${addr3.data.amphoe}</span>`
          const province = addr3.correct.includes(addr3.data.province) ? `จังหวัด${addr3.data.province}` : `<span class="red--text">จังหวัด${addr3.data.province}</span>`
          const zipcode = addr3.correct.includes(addr3.data.zipcode) ? `${addr3.data.zipcode}` : `<span class="red--text">${addr3.data.zipcode}</span>`
          const note = addr3.data.note.length === 0 ? '' : `<span class="grey--text">(${addr3.data.note})</span>`

          this.addr3Html = `<span>${address} ${district} ${amphoe} ${province} ${zipcode} ${note}</span>`
          this.addr3 = `${addr3.address} แขวง${addr3.data.district} เขต${addr3.data.amphoe} จังหวัด${addr3.data.province} ${addr3.data.zipcode}`
          this.zipcode3 = addr3.data.zipcode
        } else {
          this.addr3 = ''
          this.addr3Html = ''
          this.zipcode3 = ''
        }
      }
    }, 850),
    updateForm (name, val) {
      this.suggestAddress(val)
      this.$emit(`update:${name}`, val)
    },
    validateCustomerInfo () {
      const valid = this.$refs.form.validate()
      if (valid) {
        this.submitOrder()
      }
    },
    onScroll (event) {
      const element = event.currentTarget || event.target
      this.scrollTop = element.scrollTop
      this.$emit('scroll', event)
    }
  }
}
</script>

<style>
  .customer-info .v-messages__message {
    line-height: normal;
  }
  .recommend-address-text {
    font-size: 0.8em;
    color: var(--v-primary-base);
  }
  .btn-apply {
    font-size: 0.8em;
    color: #6c6c6c !important;
    text-decoration: underline;
  }
</style>

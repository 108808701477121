<template>
  <div
    class="chat-room-body">
    <div class="chat-header-bubble">
      <tag-list />
      <div class="d-flex justify-space-between">
        <div
          v-if="isLoading"
          class="chat-loading">
          <v-progress-circular
            indeterminate
            :width="3"
            :size="20"
            color="primary">
          </v-progress-circular>
        </div>
        <div
          :class="{ 'd-md-none': !drawer }"
          class="chat-navigation d-block d-lg-none">
          <v-btn
            color="primary"
            @click="clickShowOrder()">
            <v-icon>
              mdi-cart
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <virtual-list
      ref="VirtualChatBox"
      :keeps="50"
      :data-sources="reverseMessages"
      :data-component="messageBoxComponent"
      :style="{ height }"
      :estimate-size="100"
      :extra-props="{ onPreview: openPreviewImage, items: reverseMessages }"
      data-key="id"
      class="v-scroll"
      @totop="getMessages(pageId, threadId)">
      <div
        slot="footer"
        ref="VirtualChatBoxFooter">
      </div>
    </virtual-list>
    <preview-image
      v-bind="previewDialog"
      @on-close="previewClose()" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ChatProvider from '@/resources/ChatProvider'
import MessageBox from '@/components/Message/MessageBox.vue'
import PreviewImage from '@/components/Message/PreviewImage.vue'
import TagList from './TagList.vue'

export default {
  name: 'ChatBox',
  components: {
    TagList,
    PreviewImage
  },
  props: {
    threadId: {
      type: [Number, String],
      default: null
    },
    height: {
      type: [Number, String],
      default: '450px'
    },
    activeOrder: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      messageBoxComponent: MessageBox,
      next: null,
      messages: [],
      elementRef: null,
      isLoading: false,
      isPreview: false,
      srcPreview: '',
      previewDialog: {
        active: false,
        src: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      page: 'Chat/page',
      pageId: 'Chat/pageId',
      platform: 'Chat/platform',
      customers: 'Chat/customers',
      newMessages: 'Chat/newMessages',
      drawer: 'Notification/drawerInbox'
    }),
    tags () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.tags || []
    },
    reverseMessages () {
      return this.messages.slice().reverse()
    }
  },
  watch: {
    threadId (newValue) {
      this.getMessages(this.pageId, newValue)
    }
  },
  mounted () {
    const customerId = this.$route.params.customerId
    this.$watch(`customers.${customerId}`, (newValue) => {
      const customer = newValue
      if (customer && customer.unreadCount !== 0 && customer.message) {
        customer.unreadCount = 0
        customer.readAt = this.$dayjs().format()
        // add or update
        const fbMessage = this.messages.find((i) => i.mid && i.mid === customer.message.mid)
        if (fbMessage) {
          const messages = this.messages.filter((i) => i.mid !== customer.message.mid)
          this.messages = [
            {
              ...fbMessage,
              ...customer.message
            },
            ...messages
          ]
        } else {
          // add or update
          const message = this.messages.find((i) => i.id === customer.message.id)
          if (message) {
            const messages = this.messages.filter((i) => i.id !== customer.message.id)
            this.messages = [
              {
                ...message,
                ...customer.message
              },
              ...messages
            ]
          } else {
            this.messages = [
              customer.message,
              ...this.messages
            ]
          }
        }
        this.$nextTick(() => {
          if (this.$refs.VirtualChatBox) {
            this.$refs.VirtualChatBox.scrollToBottom()
          }
        })
        this.setCustomer(customer)
      }
    })
    this.getMessages(this.pageId, this.threadId)
  },
  methods: {
    ...mapActions({
      setCustomer: 'Chat/setCustomer'
    }),
    clickShowOrder () {
      this.$emit('update:activeOrder', true)
    },
    openPreviewImage (src) {
      this.previewDialog = {
        active: true,
        src
      }
    },
    previewClose () {
      this.previewDialog = {
        active: false,
        src: ''
      }
    },
    async getMessages (pageId, threadId) {
      if (!pageId || !threadId) return
      if (this.next !== 'done') {
        const initHeight = this.$refs.VirtualChatBox ? this.$refs.VirtualChatBox.$el.scrollHeight : 0
        this.isLoading = true
        // changes: fetchConversation
        // old data
        // {
        //   "id": "m_RbOHu0ZVOwint6J_uWkpStfru34SFJCqAdZ9-XlJWwy0gzCHjT3Y3hnSkwQSREpQQGJqOhfbBVITFapxtbSa8g",
        //   "message": "Okay",
        //   "from": {
        //       "name": "Posathorn Atiyutpakpol",
        //       "email": "2117825224990565@facebook.com",
        //       "id": "2117825224990565"
        //   },
        //   "createdTime": "2021-08-08T08:25:21+0000"
        // }
        const conversation = await ChatProvider.fetchConversation({ pageId, threadId }, {
          next: this.next
        }).finally(() => {
          this.isLoading = false
        })
        let results = conversation.results
        if (this.platform === 'instagram') {
          results = conversation.results.map((item) => ({
            ...item,
            from: {
              id: item.from.id,
              name: item.from.username,
              email: ''
            }
          }))
        }
        this.messages = [
          ...this.messages,
          ...results
        ]
        if (!this.next) {
          this.$nextTick(() => {
            if (this.$refs.VirtualChatBox) {
              this.$refs.VirtualChatBox.scrollToBottom()
              // attempting
              setTimeout(() => {
                this.$refs.VirtualChatBox.scrollToBottom()
                // attempting
                setTimeout(() => {
                  this.$refs.VirtualChatBox.scrollToBottom()
                  // attempting
                  setTimeout(() => {
                    this.$refs.VirtualChatBox.scrollToBottom()
                  }, 250)
                }, 250)
              }, 250)
            }
          })
        } else {
          this.$nextTick(() => {
            if (this.$refs.VirtualChatBox) {
              const marginTop = (this.$refs.VirtualChatBox.$el.scrollHeight - initHeight)
              this.$refs.VirtualChatBox.$el.scrollTop = marginTop
            }
          })
        }
        this.next = conversation.cursor
      }
    }
  }
}

</script>

<style scoped>
  .chat-room-body {
    position: relative;
    background-color: #f4f4f4;
  }
  .chat-header-bubble {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .chat-loading {
    position: relative;
    display: block;
    width: 100%;
    padding: 14px 12px;
    text-align: center;
  }
  .chat-navigation {
    position: absolute;
    right: 0;
    margin: 12px 24px;
  }
  .v-scroll {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 90px 0 0 0;
    overflow-y: scroll;
    overflow-x: hidden;
  }

</style>

<style>
</style>

<template>
  <div class="send-bill">
    <v-dialog
      :value="isActiveSendBill"
      max-width="960"
      @click:outside="closeSendBill()"
      @keydown.esc="closeSendBill()">
      <v-card>
        <div class="text-right">
          <v-btn
            icon
            @click="closeSendBill()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <label
            style="font-size: 16px;"
            class="font-weight-bold">
            คำสั่งซื้อของ  {{ customerName }}
          </label>
          <v-textarea
            v-model="billText"
            class="mt-4"
            full-width
            outlined
            no-resize>
          </v-textarea>
          <v-btn
            color="primary"
            outlined
            @click="sendReply($event)">
            ส่ง inbox
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ChatProvider from '@/resources/ChatProvider'

export default {
  name: 'SendBill',
  props: {
    isActiveSendBill: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      customerId: this.$route.params.customerId,
      billText: null
    }
  },
  computed: {
    ...mapGetters({
      customers: 'Chat/customers',
      summaryText: 'Chat/summaryText',
      pageId: 'Chat/pageId'
    }),
    customerName () {
      const customer = this.customers[this.customerId] || {}
      return customer.name || ''
    },
    stripHtmlSummaryText () {
      const val = this.stripHtml(this.summaryText)
      return val
    }
  },
  watch: {
    stripHtmlSummaryText (value) {
      this.billText = value
    }
  },
  mounted () {
    this.billText = this.stripHtml(this.summaryText)
  },
  methods: {
    ...mapActions({
      addNewMessage: 'Chat/addNewMessage'
    }),
    closeSendBill () {
      this.$emit('update:isActiveSendBill', false)
    },
    stripHtml (html) {
      const tmp = document.createElement('DIV')
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText || ''
    },
    sendReply () {
      const reply = {
        id: uuidv4(),
        for: this.customerId,
        result: {
          message: this.stripHtml(this.billText),
          createdTime: this.$dayjs().format()
        },
        status: 'sending'
      }
      this.addNewMessage(reply)
      ChatProvider.sendMessage({
        pageId: this.pageId,
        customerId: this.customerId
      }, {
        message: this.stripHtml(this.billText),
        customerName: this.customerName,
        customer: this.customerId,
        tid: reply.id
      }).then((res) => {
        this.addNewMessage({
          ...reply,
          mid: res.mid,
          error: res.error,
          status: res.status === 400 ? 'failed' : 'read'
        })
        this.$emit('update:isActiveSendBill', false)
      })
    }
  }
}
</script>

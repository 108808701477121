import HttpRequest from './HttpRequest'

class PromoProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  fetchPromoCode = ({ pageId, threadId }, payload, config) => this.get(
    `/pages/${pageId}/chats/${threadId}/promo_codes.json`,
    { payload },
    config
  ).catch((err) => this.handleError(err))
}

export default new PromoProvider()

<template>
  <div class="px-2 mb-3">
    <img
      :src="src"
      class="w-100">
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChatProvider from '@/resources/ChatProvider'

export default {
  props: {
    src: {
      type: [String],
      required: true
    },
    items: {
      type: Array,
      default: () => ([])
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      shopInfo: 'Chat/shopInfo'
    })
  },
  watch: {
    src (value) {
      this.autoVerify(value)
    }
  },
  mounted () {
    this.autoVerify(this.src)
  },
  methods: {
    ...mapActions({
      setOrderLoading: 'Notification/setOrderLoading',
      showErrorMessage: 'Notification/showErrorMessage'
    }),
    async autoVerify (paymentSlip) {
      if (this.active && this.shopInfo.shop.autoVerifyPayment) {
        const pageId = this.pageId
        const payload = {
          banks: this.items,
          paymentSlip
        }
        this.setOrderLoading(true)
        const response = await ChatProvider.verifySlip({ pageId }, payload)
          .finally(() => {
            this.setOrderLoading(false)
          })
        if (response && response.result) {
          this.showErrorMessage({
            type: 'success',
            message: 'ตรวจสอบสลิปแล้ว'
          })
          this.$emit('verify', response.result)
        } else {
          this.showErrorMessage({
            type: 'error',
            message: 'สลิปไม่ถูกต้อง'
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <template v-for="item in items">
      <product-selection-card
        :key="`productset-item-${item.id}`"
        :page-id="pageId"
        :item-code="item.code"
        :item-image="item.image"
        :item-name="item.name"
        :item-description-long="item.descriptionLong"
        :item-subproducts="item.subproducts"
        :item-price="item.price"
        :item-object-available="item.objectAvailable"
        :item-count-selected="item.countSelected"
        :pre-order="preOrder"
        type="set"
        @click:select="addProduct(item)"
        @click:senddetail="$emit('click:senddetail', $event)" />
    </template>
    <infinite-loading
      :identifier="infiniteId"
      spinner="spiral"
      @infinite="infiniteHandler">
      <div
        slot="no-more"
        class="caption secondary--text">
        ไม่มีสินค้าเพิ่มเติม :)
      </div>
      <div
        slot="no-results"
        class="caption secondary--text">
        ไม่มีสินค้า :)
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import _ from 'lodash'
import ProductProvider from '@/resources/ProductProvider'
import { mapActions } from 'vuex'
import ProductSelectionCard from './ProductSelectionCard.vue'

export default {
  components: { ProductSelectionCard },
  props: {
    pageId: {
      type: [String, Number],
      required: true
    },
    stockId: {
      type: [String, Number],
      required: true
    },
    preOrder: {
      type: Boolean,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    },
    selected: {
      type: Array,
      default: () => ([])
    },
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      page: 1,
      products: [],
      infiniteId: +new Date()
    }
  },
  computed: {
    items () {
      return this.products.map((item) => {
        let count = 0
        this.selected.forEach((i) => {
          if (i.id && i.id === item.id) count += parseInt(i.quantity, 0)
          else if (i.productSetId && i.productSetId === item.id) count += parseInt(i.quantity, 0)
        })
        return {
          ...item,
          countSelected: count
        }
      })
    }
  },
  watch: {
    search () {
      this.page = 1
      this.products = []
      this.infiniteId += 1
    }
  },
  methods: {
    ...mapActions({
      addSetPurchases: 'Chat/addSetPurchases',
      setSelectedSetProduct: 'Chat/setSelectedSetProduct'
    }),
    addProduct (item) {
      const selectedSetProduct = {
        thumb: item.imageThumb,
        name: item.name,
        price: Number(item.price).toFixed(2),
        quantity: 1,
        productSetId: item.id,
        objectAvailable: item.objectAvailable
      }
      const setProduct = this.selected.find((i) => i.productSetId === item.id)
      if (this.preOrder || !setProduct || (setProduct && setProduct.quantity < item.objectAvailable)) {
        if (this.orderId) {
          this.addSetPurchases({
            id: this.orderId,
            product: selectedSetProduct
          })
        } else {
          this.setSelectedSetProduct(selectedSetProduct)
        }
      }
    },
    async getProducts ({
      page = 1,
      perPage = 7
    }) {
      const pageId = this.pageId
      const stockSelection = this.stockId
      this.loading = true
      const { setResults } = await ProductProvider.getProducts({ pageId }, {
        page,
        perPage,
        search: this.search,
        stockSelection
      }).finally(() => {
        this.loading = false
      })
      return setResults
    },
    infiniteHandler ($state) {
      this.getProducts({
        page: this.page
      }).then((products) => {
        if (products && products.length) {
          this.page += 1
          this.products = _.concat(this.products, products)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="order-header">
    <div class="d-flex justify-space-between">
      <v-btn
        :class="{ 'd-md-none': !drawer }"
        class="d-block d-lg-none mr-1"
        color="primary"
        icon
        @click="clickCloseOrder()">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-select
        label="ออเดอร์"
        :value="selectedOrder"
        :items="ordersSelection"
        :item-value="item => item"
        item-text="price"
        class="mr-1"
        style="max-width: 170px;"
        hide-details
        outlined
        dense
        @change="onChangeOrder($event)">
        <template v-slot:selection="{ item }">
          <span
            class="text--book"
            style="width: 100%; overflow:hidden;
                    white-space:nowrap;
                    text-overflow: ellipsis;">
            {{ item.invoiceNumber }} ({{ item.price | formatNumber }} ฿)
          </span>
        </template>
        <template v-slot:item="{ item }">
          <span class="text--book">
            {{ item.invoiceNumber }} ({{ item.price | formatNumber }} ฿)
          </span>
        </template>
      </v-select>
      <v-btn
        class="mr-1"
        color="primary"
        rounded
        depressed
        @click="newOrder">
        <img
          :src="plusButton"
          alt="plusButton">
        <span class="ml-1 d-none d-sm-block">
          เพิ่มออเดอร์
        </span>
      </v-btn>
      <v-tooltip
        v-if="render && enableHolding"
        bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="colorHolding"
            outlined
            rounded
            depressed
            v-bind="attrs"
            v-on="on"
            @click="chageStateHolding()">
            <v-icon
              v-if="selectedOrder.holding"
              small>
              mdi-play
            </v-icon>
            <v-icon
              v-else
              small>
              mdi-pause
            </v-icon>
          </v-btn>
        </template>
        <span>
          พักออเดอร์
        </span>
      </v-tooltip>
      <v-spacer />
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="primary"
            dark
            v-bind="attrs"
            v-on="on">
            <v-icon>
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="onRemoveOrder()">
            <v-icon> mdi-delete </v-icon>
            <span class="text--book"> ลบออเดอร์ </span>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div
      class="order-status"
      :style="{ height: statusHeight + 'px', minHeight: '54px' }">
      <div
        class="order-wrap-status"
        :style="{ top: statusTop + 'px'}">
        <div>
          <label
            class="text--book body-2 mb-2"
            :style="{ opacity: opacity }"> สถานะออเดอร์ </label>
          <div class="d-flex justify-center">
            <div
              v-for="(item, index) in stages"
              :key="item.id"
              class="d-flex">
              <!-- <div
                v-if="index > 0"
                :class="{'s-success': item.id <= stage}"
                class="order-state order-state-dot dot-left">
              </div> -->
              <div
                :style="{ width: '78px' }"
                class="text-center">
                <div
                  :class="{'s-success': showSuccess(item)}"
                  class="order-state">
                  <img
                    :src="svgIcon[index].icon"
                    style="width: 16px; height: 16px" />
                </div>
                <span
                  :style="{
                    opacity: opacity,
                    fontSize: item.id === 4 ? '10px' : '12px'
                  }"
                  :class="{'s-success': showSuccess(item)}"
                  class="order-state-text"> {{ item.name }} </span>
              </div>
              <!-- <div
                v-else
                :class="{'s-success': showSuccess(item)}"
                class="order-state order-state-dot dot-center"></div> -->
              <!-- <div
                v-if="index < stages.length - 1"
                :class="{'s-success': item.id < stage }"
                class="order-state order-state-dot dot-right"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-tabs
      :value="selectedTab"
      grow
      @change="changeTab($event)">
      <v-tab>
        <img :src="selectedTab === 0 ? itemActive : itemDefault">
        <span class="ml-2"> สินค้า </span>
      </v-tab>
      <v-tab>
        <img :src="selectedTab === 1 ? locationActive : locationDefault">
        <span class="ml-2"> ที่อยู่ </span>
      </v-tab>
      <v-tab :disabled="!selectedOrder.id">
        <img :src="selectedTab === 2 ? paymentActive : paymentDefault">
        <span class="ml-2"> ชำระเงิน </span>
      </v-tab>
      <v-tab :disabled="!selectedOrder.paymentAccepted">
        <img :src="selectedTab === 3 ? deliveredActive : deliveredDefault">
        <span class="ml-4"> จัดส่ง </span>
      </v-tab>
    </v-tabs>
    <modal
      v-bind="confirmDialog"
      @onConfirm="onDialogConfirm()"
      @onCancel="onDialogCancel()" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderProvider from '@/resources/OrderProvider'

export default {
  name: 'OrderHeader',
  props: {
    orderId: {
      type: Number,
      default: 0
    },
    selectedTab: {
      type: Number,
      default: 0
    },
    selectedOrder: {
      type: Object,
      default: () => {}
    },
    defaultStock: {
      type: Object,
      default: () => {}
    },
    newOrders: {
      type: Array,
      default: () => []
    },
    loadAsyncOrder: {
      type: Function,
      default: () => null
    },
    activeOrder: {
      type: Boolean,
      default: false
    },
    lastCustomerInfo: {
      type: Object,
      default: () => null
    },
    clientHeight: {
      type: Number,
      default: 0
    },
    scrollHeight: {
      type: Number,
      default: 0
    },
    scrollTop: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      render: false,
      stages: [
        {
          id: 0,
          name: 'สั่งจอง'
        },
        {
          id: 4,
          name: 'ตรวจสอบหลักฐาน'
        },
        {
          id: 1,
          name: 'รอพิมพ์'
        },
        {
          id: 2,
          name: 'รอแพ็ก'
        },
        {
          id: 3,
          name: 'ส่งแล้ว'
        }
      ],
      tabs: [
        {
          id: 0,
          name: 'สินค้า'
        },
        {
          id: 1,
          name: 'ที่อยู่'
        },
        {
          id: 2,
          name: 'ชำระเงิน'
        },
        {
          id: 3,
          name: 'จัดส่ง'
        }
      ],
      svgIcon: [
        { icon: require('@/assets/svg/Station/OrderStage.svg') },
        { icon: require('@/assets/svg/Station/PaymentStage.svg') },
        { icon: require('@/assets/svg/Station/PrintStage.svg') },
        { icon: require('@/assets/svg/Station/PackStage.svg') },
        { icon: require('@/assets/svg/Station/DeliveredStage.svg') }
      ],
      itemActive: require('@/assets/svg/TabBarRight/ItemActive.svg'),
      itemDefault: require('@/assets/svg/TabBarRight/ItemDefault.svg'),
      locationActive: require('@/assets/svg/TabBarRight/LocationActive.svg'),
      locationDefault: require('@/assets/svg/TabBarRight/LocationDefault.svg'),
      paymentActive: require('@/assets/svg/TabBarRight/PaymentActive.svg'),
      paymentDefault: require('@/assets/svg/TabBarRight/PaymentDefault.svg'),
      deliveredActive: require('@/assets/svg/TabBarRight/DeliveredActive.svg'),
      deliveredDefault: require('@/assets/svg/TabBarRight/DeliveredDefault.svg'),
      plusButton: require('@/assets/svg/IconButton/PlusButton.svg'),
      confirmDialog: {
        confirmType: null,
        confirmOnly: false,
        title: 'ลบข้อมูล',
        message: 'ยืนยันการลบออเดอร์นี้หรือไม่',
        confirmText: 'ตกลง',
        cancelText: 'ยกเลิก',
        active: false,
        value: null
      }
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      shopInfo: 'Chat/shopInfo',
      customers: 'Chat/customers',
      getOrder: 'Chat/getOrder',
      drawer: 'Notification/drawerInbox'
    }),
    colorHolding () {
      return this.selectedOrder.holding ? 'error' : 'success'
    },
    enableHolding () {
      return this.shopInfo.shop ? this.shopInfo.shop.enableHolding : false
    },
    stage () {
      const order = this.getOrder(this.orderId)
      return order.stage || 0
    },
    shippingType () {
      return this.shopInfo.shop ? this.shopInfo.shop.shippingTypesWithDropoff : []
    },
    banks () {
      return this.shopInfo.shop ? this.shopInfo.shop.banksWithName : []
    },
    orderIds () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.orders || []
    },
    orders () {
      return this.orderIds.map((i) => this.getOrder(i))
    },
    ordersSelection () {
      return this.newOrders.length > 0 ? this.newOrders : this.orders
    },
    defaultTaxInvoice () {
      return this.shopInfo.shop.defaultTaxInvoice
    },
    opacity () {
      const current = this.scrollHeight - this.scrollTop
      const opacity = this.scrollTop * 0.02
      return current < (this.clientHeight - 60) ? 0 : 1 - opacity
    },
    statusHeight () {
      const current = this.scrollHeight - this.scrollTop
      return current < (this.clientHeight - 60) ? 60 : (90 - this.scrollTop)
    },
    statusTop () {
      const current = this.scrollHeight - this.scrollTop
      const top = ((this.scrollTop * -1))
      if (current < (this.clientHeight - 60)) {
        return -15
      }
      return top > -15 ? top : -15
    }
  },
  watch: {
    orderId (val) {
      if (val) {
        this.render = true
      } else {
        this.render = false
      }
    }
  },
  methods: {
    ...mapActions({
      setTab: 'Chat/setTab',
      setPreOrder: 'Chat/setPreOrder',
      setTaxAble: 'Chat/setTaxAble',
      setSendBill: 'Chat/setSendBill',
      setOrderClosed: 'Chat/setOrderClosed',
      setStockId: 'Chat/setStockId',
      setSummaryText: 'Chat/setSummaryText',
      setOrder: 'Chat/setOrder',
      showErrorMessage: 'Notification/showErrorMessage',
      closeErrorMessage: 'Notification/closeErrorMessage'
    }),
    async chageStateHolding () {
      const order = this.selectedOrder
      order.holding = !this.selectedOrder.holding
      this.$emit('update:selectedOrder', order)
      this.$emit('submit')
    },
    onDialogConfirm () {
      const pageId = this.pageId
      const orderId = this.orderId

      this.removeOrder(pageId, orderId)
      this.confirmDialog.active = false
    },
    onDialogCancel () {
      this.confirmDialog.active = false
    },
    onRemoveOrder () {
      this.confirmDialog.active = true
    },
    async removeOrder (pageId, orderId) {
      await OrderProvider.deleteOrder({ pageId }, {
        orderId
      })
      this.showErrorMessage({
        type: 'success',
        message: 'ลบออเดอร์เรียบร้อยแล้ว'
      })
      setTimeout(() => {
        this.closeErrorMessage()
      }, 5000)
      this.loadAsyncOrder()
    },
    clickCloseOrder () {
      this.$emit('update:activeOrder', false)
    },
    onChangeOrder (e) {
      this.$emit('update:selectedOrder', e)
      this.$emit('input', e)
      this.setPreOrder(e.preorder)
      this.setTaxAble(e.taxable)
      this.setSendBill(e.sendBill)
      this.setOrderClosed(e.closed)
      this.setStockId(e.stockId)
      this.setSummaryText(e.summaryText)
    },
    changeTab (e) {
      this.setTab(e)
    },
    showSuccess (item) {
      if (this.stage === 0) {
        return [0].includes(item.id)
      }
      if (this.stage === 4) {
        return [0, 4].includes(item.id)
      }
      if (this.stage === 1) {
        return [0, 4, 1].includes(item.id)
      }
      if (this.stage === 2) {
        return [0, 4, 1, 2].includes(item.id)
      }
      if (this.stage === 3) {
        return [0, 4, 1, 2, 3].includes(item.id)
      }
      return false
    },
    async newOrder () {
      let stockId = this.shopInfo.stocks[0].id
      if (this.defaultStock) {
        stockId = this.defaultStock.id
      }

      if (this.selectedOrder.id) {
        const order = {
          id: null,
          name: this.lastCustomerInfo.name,
          address: this.lastCustomerInfo.addressWithoutZipcode,
          postcode: this.lastCustomerInfo.postcode,
          tel: this.lastCustomerInfo.tel,
          email: this.lastCustomerInfo.email,
          taxId: this.lastCustomerInfo.taxId,
          price: 0.00,
          annotation: '',
          orderDiscount: '0.00',
          stockId,
          shippingType: this.shippingType[0],
          shippingFee: 0.00,
          slug: null,
          purchases: [],
          setPurchases: [],
          orderBank: this.banks[0].value,
          promoCodeDiscount: 0.00
        }

        await this.$emit('update:selectedOrder', order)
        this.$emit('input', order)
        this.setPreOrder(false)
        this.setTaxAble(this.defaultTaxInvoice)
        this.setSendBill(false)
        this.setOrderClosed(false)
        this.setStockId(stockId)
        this.setTab(0)
      }
    }
  }
}
</script>

<style>
  .order-header .v-tab {
    padding: 0px;
  }

  .order-header .v-btn:not(.v-btn--round).v-size--default {
    min-width: 0px;
    padding: 0 12px;
  }
</style>

<style scoped>
  .order-status {
    position: relative;
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 10px;
    font-size: 1rem !important;
    border: 1px solid #BFBFBF;
    border-radius: 5px;
    overflow: hidden;
  }
  .order-wrap-status {
    position: absolute;
    width: calc(100% - 20px);
  }
  .order-state {
    width: 34px;
    height: 34px;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .order-state.s-active {
    background-color: #1BA7E1;
  }

  .order-state.s-success {
    background-color: #0D968C;
  }
  .order-state-text.s-success  {
    color: #0D968C;
  }
  .order-state-dot {
    width: 10px;
    height: 10px;
  }

  .order-state-dot.dot-left {
    margin: 15px 6px 15px 3px;
  }

  .order-state-dot.dot-right {
    margin: 15px 3px 15px 6px;
  }
    .order-state-dot.dot-center {
    margin: 15px 4px 15px 4px;
  }
</style>

<template>
  <v-dialog
    v-model="isOpen"
    max-width="960"
    @click:outside="close()">
    <v-card class="img-preview">
      <div class="text-right">
        <v-btn
          icon
          @click="close()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-img
          max-height="600"
          :src="source"
          contain />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'PreviewImage',
  props: {
    active: {
      type: Boolean,
      require: true
    },
    src: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isOpen: false,
      source: ''
    }
  },
  watch: {
    active (e) {
      this.isOpen = e
    },
    src (e) {
      this.source = e
    }
  },
  methods: {
    close () {
      this.$emit('on-close', false)
    }
  }
}
</script>

<style>

</style>

<template>
  <div class="shipping">
    <div
      v-if="closed && !canEdit"
      class="success--text text-center mt-5">
      <p> ปิดรายการเรียบร้อยแล้ว </p>
      <v-btn
        color="primary"
        rounded
        depressed
        @click="canEdit = true">
        <v-icon left>
          mdi-pencil
        </v-icon>
        แก้ไข
      </v-btn>
    </div>
    <div v-else-if="canClose">
      <div
        class="order-main"
        :style="{'height': containerHeight, 'padding-bottom': paddingBottom}"
        @scroll="onScroll($event)">
        <div class="mb-3">
          <label class="text--book d-block mb-2"> เลขพัสดุ </label>
          <v-text-field
            :value="closedNote"
            placeholder="กรอกเลขพัสดุ"
            outlined
            hide-details
            dense
            @input="updateShipping('closedNote', $event)">
          </v-text-field>
        </div>
        <div class="mb-3">
          <label class="text--book d-block mb-2"> ค่าส่ง /ค่ากล่อง </label>
          <v-text-field
            :value="closedCost"
            type="number"
            placeholder="0.0"
            outlined
            dense
            hide-details
            @input="updateShipping('closedCost', $event)">
          </v-text-field>
        </div>
        <div class="mt-2">
          <label class="text--book"> วันที่จัดส่ง </label>
          <v-dialog
            ref="dialog"
            v-model="datePickerModal"
            width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateFormatted"
                prepend-inner-icon="mdi-calendar"
                v-bind="attrs"
                outlined
                hide-details
                dense
                v-on="on">
              </v-text-field>
            </template>
            <v-date-picker
              :value="closedDateField"
              full-width
              @input="updateShipping('closedDateField', $event)">
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                @click="datePickerModal = false">
                ตกลง
              </v-btn>
              <v-btn
                depressed
                color="error"
                @click="datePickerModal = false">
                ยกเลิก
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="mt-2">
          <label class="text--book"> เวลาที่จัดส่ง </label>
          <v-text-field
            :value="closedTimeField"
            type="time"
            prepend-inner-icon="mdi-clock"
            class="mt-2"
            outlined
            hide-details
            dense
            @input="updateShipping('closedTimeField', $event)">
          </v-text-field>
        </div>
      </div>
      <div class="order-action">
        <v-btn
          color="primary"
          depressed
          rounded
          @click="submitClosePayment()">
          <img
            :src="saveButton"
            alt="saveButton"
            class="mr-2">
          บันทึก
        </v-btn>
      </div>
    </div>
    <div
      v-else
      class="red--text text-center mt-5">
      สินค้าหมด ไม่สามารถปิดรายการได้
    </div>
    <v-overlay
      :absolute="true"
      :opacity="0.08"
      :value="isLoading">
      <v-progress-circular
        :indeterminate="isLoading"
        color="primary">
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderProvider from '@/resources/OrderProvider'

export default {
  name: 'Shipping',
  props: {
    closedNote: {
      type: String,
      default: ''
    },
    closedCost: {
      type: String,
      default: ''
    },
    closedDateField: {
      type: String,
      default: null
    },
    closedTimeField: {
      type: String,
      default: null
    },
    canClose: {
      type: Boolean,
      default: false
    },
    closed: {
      type: Boolean,
      default: false
    },
    loadAsyncOrder: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      saveButton: require('@/assets/svg/IconButton/SaveButton.svg'),
      datePickerModal: false,
      timePickerModal: false,
      isSubmitting: false,
      canEdit: false,
      scrollTop: 0
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      orderId: 'Chat/orderId',
      isLoading: 'Notification/isLoading'
    }),
    dateFormatted () {
      return this.$dayjs(this.closedDateField, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('DD-MM-YYYY')
    },
    containerHeight () {
      return `calc(100vh - ${ 343 - this.scrollTop }px)`
    },
    paddingBottom () {
      return this.scrollTop < 60 ? `${this.scrollTop}px` : '60px'
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'Notification/showErrorMessage',
      closeErrorMessage: 'Notification/closeErrorMessage',
      openLoading: 'Notification/openLoading',
      closeLoading: 'Notification/closeLoading'
    }),
    onScroll (event) {
      const element = event.currentTarget || event.target
      this.scrollTop = element.scrollTop
      this.$emit('scroll', event)
    },
    updateShipping (name, val) {
      this.$emit(`update:${name}`, val)
    },
    async submitClosePayment () {
      const pageId = this.pageId
      if (!this.isSubmitting) {
        this.isSubmitting = true
        this.openLoading()
        const payload = {
          orderId: this.orderId,
          closedNote: this.closedNote,
          closedAt: `${this.closedDateField} ${this.closedTimeField}`,
          closedCost: this.closedCost
        }
        await OrderProvider.chatSubmitClose({ pageId }, payload)
        this.showErrorMessage({
          type: 'success',
          message: 'ปิดออเดอร์เรียบร้อยแล้ว'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 5000)
        this.loadAsyncOrder()
        this.isSubmitting = false
        this.canEdit = false
        this.closeLoading()
      }
    }
  }
}
</script>

<style>
  .shipping {
    /* padding: 8px 0; */
  }
</style>

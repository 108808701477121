<template>
  <v-tooltip
    v-if="getAdministrators.length"
    bottom>
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="administrator-facepile"
        v-on="on">
        <v-avatar
          v-if="getAdministrators.length"
          size="36"
          class="member">
          <img
            :src="getAdministrators[0].avatar"
            :alt="getAdministrators[0].username"
          >
        </v-avatar>
        <v-avatar
          v-if="getAdministrators.length > 1"
          size="36"
          color="info"
          class="member border">
          <span class="white--text headline">+{{ getAdministrators.length - 1 }}</span>
        </v-avatar>
      </div>
    </template>
    <div
      v-for="admin in getAdministrators"
      :key="admin.userId">
      {{ admin.username }}
    </div>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AdminList',
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      customers: 'Chat/customers',
      administrators: 'Chat/administrators'
    }),
    getAdministrators () {
      const administrators = Object.keys(this.administrators).map((key) => this.administrators[key])
      const customer = this.customers[this.$route.params.customerId] || {}
      const threadId = customer.threadId
      return Array.isArray(administrators) ? administrators.filter(
        (item) => item.action === 'subscribe' && item.chatId === threadId
      ) : []
    }
  }
}

</script>

<style scoped>
  .administrator-facepile {
    min-width: 60px;
    cursor: default;
    float: left;
    margin-left: 20px;
    overflow: hidden;
    padding: 1px 0 0 20px;
  }

  .administrator-facepile .member {
    margin: 0 0 0 -20px;
    float: left;
    z-index: 1;
  }
  .administrator-facepile .member.border {
    border: 2px solid #f0f0f0 !important;
  }
  /* .member {
      background-color: #dfe1e6;
      border-radius: 25em;
      color: #172b4d;
      display: block;
      float: left;
      height: 32px;
      margin: 0 4px 4px 0;
      overflow: visible;
      position: relative;
      width: 32px;
      text-decoration: none;
      -webkit-user-select: none;
      user-select: none;
      z-index: 0;
  } */
  .member .headline {
      line-height: inherit;
  }
</style>

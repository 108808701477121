<template>
  <div
    v-if="getTagsWithColor.length"
    class="tag-container"
    :class="{ 'collpase': collpase }">
    <div class="tag-body">
      <v-chip
        v-for="tag in getTagsWithColor"
        :key="tag[0]"
        class="mb-2 mr-2"
        close
        :color="tag[1]"
        text-color="white"
        small
        label
        @click:close="remove(tag[0])"
      >
        {{ tag[0] }}
      </v-chip>
    </div>
    <div class="tag-footer">
      <v-btn
        icon
        color="primary"
        small
        @click="toggleCollapse($event)">
        <v-icon v-if="collpase">
          mdi-chevron-up
        </v-icon>
        <v-icon v-else>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChatProvider from '@/resources/ChatProvider'

export default {
  name: 'TagList',
  data () {
    return {
      collpase: false
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      customers: 'Chat/customers'
    }),
    customerName () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.name || ''
    },
    threadId () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.threadId || null
    },
    getTagsWithColor () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.tagsWithColor || []
    },
    getTags () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.tags || []
    }
  },
  methods: {
    ...mapActions({
      setCustomer: 'Chat/setCustomer'
    }),
    async updateTag (pageId, customerId, threadId, tags, tagsWithColor) {
      await ChatProvider.updateTag({ pageId, customerId }, {
        tags,
        name: this.customerName
      })
      this.setCustomer({
        id: customerId,
        tags,
        tagsWithColor
      })
    },
    remove (e) {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      const threadId = this.threadId
      const tags = this.getTags
      const tagsWithColor = this.getTagsWithColor
      const index = tags.indexOf(e)
      tags.splice(index, 1)
      tagsWithColor.splice(index, 1)
      this.updateTag(pageId, customerId, threadId, tags, tagsWithColor)
    },
    toggleCollapse () {
      this.collpase = !this.collpase
    }
  }
}

</script>
<style lang="scss">
  .tag-container .v-icon.v-icon.v-icon--link {
    color: #f0f0f0 !important;
  }
</style>
<style scoped>
  .tag-container {
    position: relative;
    display: flex;
    background-color: #fff;
    padding: 0 12px;
    z-index: 1;
    height: 32px;
    max-height: 32px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .tag-container.collpase {
    height: 100%;
    max-height: 300px;
  }
  .tag-body {
    padding: 2px 0;
    width: calc(100% - 34px);
  }
  .tag-footer {
    width: 34px;
  }
  /* .chat-tags {
    max-height: 60px;
    overflow-x: hidden;
  }

  .chat-tags::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  .chat-tags::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .chat-tags::-webkit-scrollbar-thumb {
    background: #888;
  }

  .chat-tags::-webkit-scrollbar-thumb:hover {
    background: #555;
  } */
</style>

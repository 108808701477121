<template>
  <div
    class="d-flex flex-column">
    <div
      :class="{ 'justify-end mr-4': sendFromPage }"
      class="d-flex ml-4">
      <div
        class="message-container"
        :class="{ 'text-right': sendFromPage, 'text-left': !sendFromPage }">
        <div
          class="message-body">
          <div
            v-if="source.attachments"
            class="message-image can-preview">
            <template
              v-for="(attach, i) in source.attachments.data">
              <div
                v-if="attach.imageData"
                :key="i">
                <v-img
                  :src="attach.imageData.previewUrl"
                  :lazy-src="attach.imageData.previewUrl"
                  width="100%"
                  max-width="320px"
                  alt="no image"
                  contain
                  eager
                  @click="onPreview(attach.imageData.url)" />
                <div v-if="!sendFromPage">
                  <v-btn
                    v-if="tab === 2"
                    class="message-btn"
                    color="#3a92b6"
                    block
                    rounded
                    @click="useImage(attach)">
                    <v-icon
                      color="white"
                      class="mr-1">
                      mdi-credit-card
                    </v-icon>
                    <span class="white--text">
                      ใช้เป็นหลักฐานการโอนเงิน
                    </span>
                  </v-btn>
                </div>
              </div>
            </template>
          </div>
          <div
            v-else-if="source.sticker"
            class="message-sticker">
            <img :src="source.sticker" />
          </div>
          <div
            v-else-if="source.base64"
            class="message-image">
            <img
              :src="source.base64" />
          </div>
          <div
            v-if="source.message"
            :class="{
              'primary': sendFromPage,
              'white--text': sendFromPage,
              'white': !sendFromPage,
              'chat-nodate': !showDate,
              'chat-nodate-first': conversationFirst,
              'chat-nodate-last': conversationLast,
            }"
            class="message-text"
            v-html="parseTextToUrls(source.message)">
          </div>
        </div>
        <div :class="{ 'text-right': sendFromPage }">
          <p
            v-if="source.status === 'sending'"
            class="caption text--book">
            sending...
          </p>
          <p
            v-else-if="source.status === 'failed'"
            class="caption error--text">
            <v-tooltip
              v-if="source.error"
              max-width="320"
              left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  class="mr-1"
                  color="error"
                  small
                  v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span> {{ source.error }} </span>
            </v-tooltip>
            ส่งข้อความล้มเหลว
          </p>
          <p
            v-else-if="showDate"
            class="caption text--book">
            {{ source.createdTime | formatTime }}น.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as urlRegex from 'url-regex-safe'

export default {
  name: 'MeesageBox',
  props: {
    index: {
      type: Number,
      required: true
    },
    source: {
      type: Object,
      default: () => {}
    },
    onPreview: {
      type: Function,
      default: () => {}
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      shopInfo: 'Chat/shopInfo',
      orderId: 'Chat/orderId',
      orderPaymentIndex: 'Chat/orderPaymentIndex',
      tab: 'Chat/tab'
    }),
    bankAccounts () {
      return this.shopInfo.bankAccounts ? this.shopInfo.bankAccounts : []
    },
    sendFromPage () {
      return this.source.from.id !== this.$route.params.customerId
    },
    conversationFirst () {
      const isMatch = this.index === 0
      || (this.parseNoTime(this.items[this.index - 1].createdTime) !== this.parseNoTime(this.items[this.index].createdTime))
      return isMatch && !this.showDate
    },
    conversationLast () {
      const isMatch = this.index === 0
      || (this.parseNoTime(this.items[this.index - 1].createdTime) === this.parseNoTime(this.items[this.index].createdTime)
      && this.items[this.index - 1].from.id === this.items[this.index].from.id)

      return isMatch && this.showDate
    },
    showDate () {
      const isMatch = this.index === (this.items.length - 1)
      || this.items[this.index].from.id !== this.items[this.index + 1].from.id
      || this.parseNoTime(this.items[this.index].createdTime) !== this.parseNoTime(this.items[this.index + 1].createdTime)

      return isMatch
    }
  },
  methods: {
    ...mapActions({
      setImage: 'Chat/setImage',
      setSubPaymentImage: 'Chat/setSubPaymentImage'
    }),
    parseTextToUrls (text) {
      let html = text
      const urls = text.match(
        urlRegex()
      ) || []
      urls.forEach((url) => {
        let fullUrl = url
        if (!url.startsWith('http://')
          && !url.startsWith('https://')) {
            fullUrl = `http://${fullUrl}`
        }
        html = html.replace(url, `<a href="${fullUrl}" target="_blank">${url}</a>`)
      })
      return `<div>${html}</div>`
    },
    parseNoTime (date) {
      return this.$dayjs(date).format('YYYY-MM-DDTHH:mm')
    },
    useImage (attach) {
      if (this.bankAccounts.length) {
        this.setSubPaymentImage({
          id: this.orderId,
          index: this.orderPaymentIndex,
          url: attach.imageData.url
        })
      } else {
        this.setImage({ id: this.orderId, url: attach.imageData.url })
      }
    }
  }
}
</script>
<style>
  .message-image {
    border-radius: 1.3em;
  }
  .message-image .v-image{
    width: 100%;
  }
  .message-image.can-preview .v-image {
    cursor: pointer;
  }
  .message-sticker .v-image {
    width: 120px;
  }
  .message-image .v-image {
    border-radius: 18px;
  }
</style>
<style scope>
  .message-container {
    margin: 1px 0;
    width: 100%;
  }
  .message-body {
    display: inline-block;
    max-width: 60%;
  }
  .message-text {
    width: 100%;
    word-break: break-word;
    white-space: break-spaces;
    padding: 7px 12px 8px;
    border-radius: 18px;
    text-align: left;
  }
  .text-left .message-text a {
    color: #000000;
  }
  .text-right .message-text a {
    color: #ffffff;
  }
  .text-left .message-text.chat-nodate {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .text-left .message-text.chat-nodate-first {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 4px;
  }
  .text-left .message-text.chat-nodate-last {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 18px;
  }
  .text-right .message-text.chat-nodate {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .text-right .message-text.chat-nodate-first {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 4px;
  }
  .text-right .message-text.chat-nodate-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 18px;
  }
  .message-btn {
    margin: 7px 0;
  }
  .message-image, .message-sticker {
    font-size: 0;
  }
  .message-image {
    border-radius: 1.3em;
  }
  .message-image img{
    max-width: 100%;
  }
  .message-image.can-preview img {
    cursor: pointer;
  }
  .message-sticker img {
    width: 120px;
  }
  .message-image img {
    border-radius: 18px;
  }
  .caption {
    margin: 3px 0;
  }
</style>

<template>
  <div class="d-flex mt-2">
    <v-autocomplete
      :search-input.sync="allowTag"
      :items="getAllowedTags"
      label="แท็ก"
      placeholder="เพิ่มแท็ก..."
      prepend-inner-icon="mdi-tag"
      hide-details
      outlined
      dense
      hide-no-data
      @keyup.enter="newTag($event)"
      @change="updateTag($event)">
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChatProvider from '@/resources/ChatProvider'
import ShopProvider from '@/resources/ShopProvider'

export default {
  name: 'TagSelection',
  data () {
    return {
      allowTag: ''
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      shopInfo: 'Chat/shopInfo',
      customers: 'Chat/customers'
    }),
    customerName () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.name || ''
    },
    threadId () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.threadId || null
    },
    getTags () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.tags || []
    },
    getTagsWithColor () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.tagsWithColor || []
    },
    getAllowedTags () {
      return this.shopInfo.shop ? this.shopInfo.shop.customerTags : []
    }
  },
  methods: {
    ...mapActions({
      setCustomer: 'Chat/setCustomer',
      setShopInfo: 'Chat/setShopInfo'
    }),
    async getShopInfo (pageId) {
      const response = await ShopProvider.fetchShopInfo(pageId)
      this.setShopInfo(response)
    },
    getShipnityInfo (pageId, customerId, threadId) {
      ChatProvider.getShipnityInfo({ pageId }, {
        chats: [threadId]
      }).then((res) => {
        const tags = res.result[0].tags
        const tagsWithColor = res.result[0].tagsWithColor
        this.setCustomer({
          id: customerId,
          tags,
          tagsWithColor
        })
        this.tags = tags
        this.tagsWithColor = tagsWithColor
      })
    },
    async newTag () {
      const pageId = this.pageId
      const threadId = this.threadId
      const customerId = this.$route.params.customerId
      const customerName = this.customerName
      const allowTag = this.allowTag
      let tags = this.getTags

      if (allowTag && tags.indexOf(allowTag) === -1) {
        tags = [allowTag, ...tags]
        await ChatProvider.updateTag({ pageId, customerId }, {
          tags,
          name: customerName
        })
        this.getShopInfo(pageId)
        this.getShipnityInfo(pageId, customerId, threadId)
        this.allowTag = ''
      }
    },
    async updateTag (e) {
      const pageId = this.pageId
      const threadId = this.threadId
      const customerId = this.$route.params.customerId
      const customerName = this.customerName
      let tags = this.getTags
      if (e && tags.indexOf(e) === -1) {
        tags = [e, ...tags]
        await ChatProvider.updateTag({ pageId, customerId }, {
          tags,
          name: customerName
        })
        this.getShipnityInfo(pageId, customerId, threadId)
        this.allowTag = ''
      }
    }
  }
}

</script>

<style scoped>

</style>

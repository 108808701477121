<template>
  <div class="chat-main-wrap w-sm-fullscreen">
    <div class="message">
      <div class="d-flex">
        <div
          id="chatbox-wrap"
          :style="{ width: chatWidth }"
          :class="{
            'd-md-none': drawer && activeOrder,
            'd-md-block': !drawer,
            'd-none': activeOrder,
          }"
          class="message-wrap d-lg-block w-md-fullscreen w-sm-fullscreen">
          <chat-header />
          <chat-box
            :thread-id="threadId"
            :height="chatHeight"
            :active-order.sync="activeOrder" />
          <chat-sender ref="ChatFooter" />
          <div
            v-if="$vuetify.breakpoint.lgAndUp"
            class="chat-resizer"
            @mousedown="initResize($event)"></div>
        </div>
        <div
          id="order-wrap"
          :class="{
            'd-md-none': drawer && !activeOrder,
            'd-md-block': !drawer,
            'd-none': !activeOrder,
          }"
          :style="{ width: `${resizer}px` }"
          class="order-wrap white d-lg-block w-md-fullscreen w-sm-fullscreen">
          <template v-if="selecting">
            <product-selection
              :order-id="orderId"
              :selected-order="selectedOrder"
              @click:close="selecting = false" />
          </template>
          <template v-else>
            <order-header
              :order-id="orderId"
              :selected-tab.sync="tab"
              :selected-order.sync="selectedOrder"
              :default-stock="defaultStock"
              :new-orders="newOrders"
              :load-async-order="() => loadAsyncOrder(pageId, threadId, $route.params.customerId)"
              :active-order.sync="activeOrder"
              :last-customer-info="lastCustomerInfo"
              :style="{ height: (222 - scrollTop) + 'px', minHeight: '165px' }"
              class="order-header"
              :scroll-top="scrollTop"
              :scroll-height="scrollHeight"
              :client-height="clientHeight"
              @input="changeOrder($event)"
              @submit="updateHolding()" />
            <product
              v-if="tab === 0"
              :order-id="orderId"
              :selected-order.sync="selectedOrder"
              :over-stock.sync="overStock"
              :submit-order="submitOrder"
              @scroll="scrollBodyTop($event)"
              @click:select-product="selecting = true">
            </product>
            <customer-info
              v-if="tab === 1"
              :customer-name.sync="selectedOrder.name"
              :customer-address.sync="selectedOrder.address"
              :customer-postcode.sync="selectedOrder.postcode"
              :customer-tel.sync="selectedOrder.tel"
              :customer-email.sync="selectedOrder.email"
              :customer-tax-id.sync="selectedOrder.taxId"
              :submit-order="submitOrder"
              @scroll="scrollBodyTop($event)">
            </customer-info>
            <payment-split-bank
              v-if="tab === 2 && displayingBankAccounts.length"
              :order-payments.sync="selectedOrder.orderPayments"
              :displaying-bank-accounts="displayingBankAccounts"
              :price="selectedOrder.price"
              :load-async-order="() => loadAsyncOrder(pageId, threadId, $route.params.customerId)"
              @scroll="scrollBodyTop($event)">
            </payment-split-bank>
            <payment
              v-if="tab === 2 && !displayingBankAccounts.length"
              :transferred-date-field.sync="selectedOrder.transferredDateField"
              :transferred-time-field.sync="selectedOrder.transferredTimeField"
              :order-bank.sync="selectedOrder.orderBank"
              :image-slip="selectedOrder.paymentSlipUrl"
              :payment-accepted="selectedOrder.paymentAccepted"
              :transferred="selectedOrder.transferred"
              :load-async-order="() => loadAsyncOrder(pageId, threadId, $route.params.customerId)"
              @scroll="scrollBodyTop($event)">
            </payment>
            <shipping
              v-if="tab === 3"
              :closed-note.sync="selectedOrder.closedNote"
              :closed-cost.sync="selectedOrder.closedCost"
              :closed-date-field.sync="selectedOrder.closedDateField"
              :closed-time-field.sync="selectedOrder.closedTimeField"
              :can-close="selectedOrder.canClose"
              :closed="selectedOrder.closed"
              :load-async-order="() => loadAsyncOrder(pageId, threadId, $route.params.customerId)"
              @scroll="scrollBodyTop($event)">
            </shipping>
            <v-overlay
              :absolute="true"
              :opacity="0.08"
              :value="isOrderLoading"
              z-index="3">
              <v-progress-circular
                color="primary"
                indeterminate>
              </v-progress-circular>
            </v-overlay>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChatProvider from '@/resources/ChatProvider'
import OrderProvider from '@/resources/OrderProvider'
import ChatHeader from './components/ChatHeader.vue'
import ChatBox from './components/ChatBox.vue'
import ChatSender from './components/ChatSender.vue'
import OrderHeader from './components/OrderHeader.vue'
import Product from './components/Product.vue'
import CustomerInfo from './components/CustomerInfo.vue'
import Payment from './components/Payment.vue'
import PaymentSplitBank from './components/PaymentSplitBank.vue'
import Shipping from './components/Shipping.vue'
import ProductSelection from './components/ProductSelection.vue'

export default {
  name: 'Message',
  components: {
    ChatHeader,
    ChatBox,
    ChatSender,
    OrderHeader,
    Product,
    CustomerInfo,
    Payment,
    Shipping,
    PaymentSplitBank,
    ProductSelection
  },
  data () {
    return {
      loading: false,
      selecting: false,
      isSubmitting: false,
      next: null,
      cancelToken: null,
      name: '...',
      tags: [],
      tagsWithColor: [],
      selectedOrder: {},
      threadId: undefined,
      orderId: undefined,
      newOrders: [],
      isNewOrder: false,
      activeOrder: false,
      lastCustomerInfo: null,
      overStock: false,
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0
    }
  },
  computed: {
    ...mapGetters({
      page: 'Chat/page',
      pageId: 'Chat/pageId',
      platform: 'Chat/platform',
      customers: 'Chat/customers',
      customerMessages: 'Chat/customerMessages',
      shopInfo: 'Chat/shopInfo',
      tab: 'Chat/tab',
      preOrder: 'Chat/preOrder',
      taxAble: 'Chat/taxAble',
      orders: 'Chat/orders',
      selectedProduct: 'Chat/selectedProduct',
      selectedSetProduct: 'Chat/selectedSetProduct',
      drawer: 'Notification/drawerInbox',
      resizer: 'Theme/resizer',
      isOrderLoading: 'Notification/isOrderLoading'
    }),
    chatHeight () {
      return 'calc(100vh - (267px + 52px))'
    },
    chatWidth () {
      return `calc(100% - ${this.resizer}px)`
    },
    defaultStock () {
      if (this.shopInfo.stocks) {
        return this.shopInfo.stocks.find((item) => item.facebookPageUid === this.pageId)
      }
      return {}
    },
    displayingBankAccounts () {
      return this.shopInfo.bankAccounts.filter(
        (item) => !item.stockId || item.stockId === this.selectedOrder.stockId
      )
    },
    shippingType () {
      return this.shopInfo.shop ? this.shopInfo.shop.shippingTypesWithDropoff : []
    },
    bankAccounts () {
      return this.shopInfo.bankAccounts ? this.shopInfo.bankAccounts : []
    },
    banks () {
      return this.shopInfo.shop ? this.shopInfo.shop.banksWithName : []
    },
    purchases () {
      return this.orders[this.orderId].purchases || []
    },
    setPurchases () {
      return this.orders[this.orderId].setPurchases || []
    },
    promoCodeDetail () {
      return this.orders[this.orderId].promoCode ? this.orders[this.orderId].promoCode : null
    },
    orderIds () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.orders || []
    },
    orderLists () {
      return this.orderIds.map((i) => this.orders[i])
    },
    defaultTaxInvoice () {
      return this.shopInfo.shop.defaultTaxInvoice
    }
  },
  watch: {
    selectedProduct (val) {
      const checkExisted = this.selectedOrder.purchases.findIndex(
        (item) => item.subproductId === val.subproductId
      )
      if (checkExisted > -1) {
        this.selectedOrder.purchases[checkExisted].quantity++
      } else {
        this.selectedOrder.purchases.push(val)
      }
    },
    selectedSetProduct (val) {
      const checkExisted = this.selectedOrder.setPurchases.findIndex(
        (item) => item.productSetId === val.productSetId
      )
      if (checkExisted > -1) {
        this.selectedOrder.setPurchases[checkExisted].quantity++
      } else {
        this.selectedOrder.setPurchases.push(val)
      }
    },
    tab () {
      this.scrollTop = 0
      this.scrollHeight = 0
      this.clientHeight = 0
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions({
      addCustomerMessages: 'Chat/addCustomerMessages',
      setMessages: 'Chat/setMessages',
      setPreOrder: 'Chat/setPreOrder',
      setTaxAble: 'Chat/setTaxAble',
      setSendBill: 'Chat/setSendBill',
      setOrderClosed: 'Chat/setOrderClosed',
      setStockId: 'Chat/setStockId',
      setCustomer: 'Chat/setCustomer',
      setStep: 'Chat/setStep',
      setHasPayment: 'Chat/setHasPayment',
      setSummaryText: 'Chat/setSummaryText',
      setOrder: 'Chat/setOrder',
      setOrderId: 'Chat/setOrderId',
      setTab: 'Chat/setTab',
      showErrorMessage: 'Notification/showErrorMessage',
      closeErrorMessage: 'Notification/closeErrorMessage',
      openLoading: 'Notification/openLoading',
      closeLoading: 'Notification/closeLoading',
      setResizer: 'Theme/setResizer',
      setOrderLoading: 'Notification/setOrderLoading'
    }),
    initResize () {
      window.addEventListener('mousemove', this.resize, false)
      window.addEventListener('mouseup', this.stopResize, false)
    },
    resize (e) {
      const chatbox = document.getElementById('chatbox-wrap')
      const element = document.getElementById('order-wrap')
      const offsetLeft = element.offsetLeft - e.clientX
      const pointerRelativeXpos = element.clientWidth + offsetLeft
      const minWidth = 400
      const clientWidth = Math.max(minWidth, pointerRelativeXpos)
      const chatboxWidth = chatbox.clientWidth + (this.resizer - clientWidth)
      if (this.resizer > clientWidth || chatboxWidth > 560) {
        this.$nextTick(() => {
          this.setResizer(clientWidth)
        })
      }
    },
    stopResize () {
      window.removeEventListener('mousemove', this.resize, false)
      window.removeEventListener('mouseup', this.stopResize, false)
    },
    async updateHolding () {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      const threadId = this.threadId
      const customer = this.customers[customerId]
      const payload = {
          chat: {
            order: {
              id: this.selectedOrder.id,
              holding: this.selectedOrder.holding
            },
            page_id: pageId,
            threadId: customer.threadId,
            facebookUserId: customer.id
          }
        }

        const { result } = await OrderProvider.chatSubmitOrder({ pageId }, payload)
        if (result) {
          this.closeLoading()
          this.showErrorMessage({
            type: 'success',
            message: 'บันทึกออเดอร์เรียบร้อยแล้ว'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 5000)
          this.loadAsyncOrder(pageId, threadId, customerId)
        }
        this.isSubmitting = false
    },
    scrollBodyTop (event) {
      const element = event.currentTarget || event.target
      this.scrollTop = element.scrollTop
      this.scrollHeight = element.scrollHeight
      this.clientHeight = element.clientHeight
    },
    async loadData () {
      this.loading = true
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      let threadId = this.threadId
      this.markAsRead(pageId, customerId)
      const customer = this.customers[customerId]
      if (customer && customer.threadId) {
        threadId = customer.threadId
        this.tags = customer.tags
        this.tagsWithColor = customer.tagsWithColor
      } else {
        await this.getThreadByCustomerId(pageId, customerId)
          .finally(() => {
            this.loading = false
          })
      }
      this.loadAsyncOrder(pageId, threadId, customerId)
      this.threadId = threadId
    },
    async getThreadByCustomerId (pageId, customerId) {
      // changes: findConversation
      // reverse participants to senders
      // old data
      // {
      //   "id": "t_2736861516333783",
      //   "senders": {
      //       "data": [
      //           {
      //               "name": "Posathorn Atiyutpakpol",
      //               "email": "2117825224990565@facebook.com",
      //               "id": "2117825224990565"
      //           },
      //           {
      //               "name": "Whale Whale",
      //               "email": "115849176470304@facebook.com",
      //               "id": "115849176470304"
      //           }
      //       ]
      //   }
      // }
      const { result } = await ChatProvider.findConversation({
        pageId,
        customerId
      })

      if (!result) {
        window.location.href = '/'
      } else {
        if (this.platform === 'instagram' && result.participants) {
          result.senders = {
            data: result.participants.data.map(({ username, userId, id }) => ({
              id,
              userId,
              name: username,
              email: ''
            })).reverse()
          }
        }
        this.setCustomer({
          id: customerId,
          threadId: result.id,
          instagramUserId: result.senders?.data[0].userId,
          name: result.senders?.data[0].name,
          updatedTime: this.$dayjs().format(),
          unreadCount: 0,
          to: `/messages/${customerId}`,
          cursors: null
        })
        this.threadId = result.id
      }
    },
    setCustomerResponse (customerId, shipinfo) {
      const {
        facebookId, facebookThreadId, facebookReadAt, tags, tagsWithColor,
        rewardLink, orderStatus,
        orderBank, paymentAccepted
      } = shipinfo
      const customer = {}
      customer.id = facebookId
      customer.threadId = facebookThreadId
      customer.orderStatus = orderStatus
      customer.orderBank = orderBank
      customer.paymentAccepted = paymentAccepted
      customer.tags = tags
      customer.tagsWithColor = tagsWithColor
      customer.readAt = facebookReadAt
      customer.rewardLink = rewardLink
      if (this.$dayjs(customer.readAt).isBefore(this.$dayjs(new Date(customer.updatedTime)))) {
        customer.unreadCount = customerId !== facebookId ? customer.beforeUnreadCount : 0
      }
      return customer
    },
    async markAsRead (pageId, customerId) {
      const shipInfo = await ChatProvider
        .chatMarkAsRead({ pageId, customerId })

      const customerResponse = this.setCustomerResponse(customerId, shipInfo.result)
      this.setCustomer(customerResponse)
    },
    emptyOrder () {
      let stockId = this.shopInfo.stocks[0].id
      if (this.defaultStock) {
        stockId = this.defaultStock.id
      }

      const order = {
        id: null,
        name: this.lastCustomerInfo.name,
        address: this.lastCustomerInfo.addressWithoutZipcode,
        postcode: this.lastCustomerInfo.postcode,
        tel: this.lastCustomerInfo.tel,
        email: this.lastCustomerInfo.email,
        taxId: this.lastCustomerInfo.taxId,
        price: 0.00,
        annotation: '',
        orderDiscount: '0.00',
        shippingType: this.shippingType[0],
        shippingFee: 0.00,
        stockId,
        slug: null, // random generate unique id
        purchases: [],
        setPurchases: [],
        orderBank: this.banks[0].value,
        promoCodeDiscount: '0.00'
      }

      this.selectedOrder = order

      this.setPreOrder(false)
      this.setTaxAble(this.defaultTaxInvoice)
      this.setSendBill(false)
      this.setOrderClosed(false)
      this.setStockId(stockId)
      this.orderId = this.selectedOrder.id

      if (!this.isNewOrder) {
        this.newOrders = this.orderLists
        this.newOrders.unshift(order)
        this.isNewOrder = true
      }
    },
    changeOrder (e) {
      this.orderId = e.id
      this.setOrderId(e.id)
      this.setTab(0)

      if (!this.isNewOrder && !e.id) {
        this.newOrders = this.orderLists
        this.newOrders.unshift(e)
        this.isNewOrder = true
      }
    },
    setDiscountFromPromoCode (selectedOrder) {
      if (selectedOrder.promoCode == null) return 0
      let sum = 0
      sum = selectedOrder.purchases.filter((option) => option._destroy !== 1).reduce((a, b) => a + (Number(b.price) || 0) * b.quantity, 0)
      sum += selectedOrder.setPurchases.filter((option) => option._destroy !== 1).reduce((a, b) => a + (Number(b.price) || 0) * b.quantity, 0)
      return selectedOrder.promoCode.discountType === 'baht'
        ? selectedOrder.promoCode.discountValue
        : sum * (Number(selectedOrder.promoCode.discountValue) / 100)
    },
    async loadAsyncOrder (pageId, threadId, customerId) {
      this.setOrderLoading(true)
      const customerInfo = this.customers[customerId]
      if (customerInfo) {
        const { customer, results } = await OrderProvider.fetchOrder({
          pageId,
          threadId
        }, {
          name: customerInfo.name,
          facebookId: customerInfo.id,
          facebookPageUid: pageId
        })

        this.lastCustomerInfo = customer

        if (results.length) {
          for (let i = 0; i < results.length; i++) {
            if (results[i].stage === 0 && !!results[i].orderBank && !results[i].paymentAccepted) {
              results[i].stage = 4
            }
            results[i].promoCodeDiscount = this.setDiscountFromPromoCode(results[i])
            results[i].shippingFee = Number(results[i].shippingFee)
            results[i].price = Number(results[i].price)
            results[i].address = results[i].addressWithoutZipcode
            results[i].orderDiscount = Number(results[i].orderDiscount).toFixed(2)
            results[i].shippingFee = Number(results[i].shippingFee).toFixed(2)
            results[i].paymentSlip = null

            if (results[i].orderBank === null) {
              results[i].orderBank = this.banks[0].value
            }

            if (!results[i].transferred) {
              results[i].transferredDateField = this.$dayjs().format('YYYY-MM-DD')
              results[i].transferredTimeField = this.$dayjs().format('HH:mm')
            } else {
              results[i].transferredDateField = this.$dayjs(results[i].transferredDateField, 'DD/MM/YYYY').format('YYYY-MM-DD')
            }

            if (!results[i].closed) {
              results[i].closedDateField = this.$dayjs().format('YYYY-MM-DD')
              results[i].closedTimeField = this.$dayjs().format('HH:mm')
            } else {
              results[i].closedDateField = this.$dayjs(results[i].closedDateField, 'DD/MM/YYYY').format('YYYY-MM-DD')
            }

            if (results[i].orderPayments.length > 0) {
              results[i].orderPayments = results[i].orderPayments.map((payment) => ({
                id: payment.id,
                orderId: payment.orderId,
                transferredDateField: this.$dayjs(payment.transferredDateField, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                transferredTimeField: payment.transferredTimeField,
                orderBank: payment.bank,
                transferNote: payment.amount,
                bankAccountId: payment.bankAccountId,
                paymentSlipUrl: payment.paymentSlipUrl,
                paymentSlip: '',
                _destroy: 0,
                paymentAccepted: payment.paymentAccepted,
                canEdit: false
              }))
            }

            if (this.bankAccounts.length > 0 && results[i].orderPayments.length === 0) {
              results[i].orderPayments.push({
                orderId: results[i].id,
                transferredDateField: this.$dayjs().format('YYYY-MM-DD'),
                transferredTimeField: this.$dayjs().format('HH:mm'),
                orderBank: this.bankAccounts[0].bank,
                transferNote: results[i].price,
                bankAccountId: this.bankAccounts[0].id,
                paymentAccepted: null,
                paymentSlip: '',
                _destroy: 0,
                canEdit: true
              })
            }

            for (let c = 0; c < results[i].purchases.length; c++) {
              results[i].purchases[c].price = Number(results[i].purchases[c].price).toFixed(2)
              results[i].purchases[c]._destroy = 0
            }

            for (let c = 0; c < results[i].setPurchases.length; c++) {
              results[i].setPurchases[c].price = Number(results[i].setPurchases[c].price).toFixed(2)
              results[i].setPurchases[c]._destroy = 0
            }

            this.setOrder(results[i])
          }

          this.setCustomer({
            id: customerInfo.id,
            orders: results.map((i) => i.id)
          })

          this.selectedOrder = results[0]

          this.setPreOrder(this.selectedOrder.preorder)
          this.setTaxAble(this.selectedOrder.taxable)
          this.setSendBill(this.selectedOrder.sendBill)
          this.setOrderClosed(this.selectedOrder.closed)
          this.setStockId(this.selectedOrder.stockId)
          this.setSummaryText(this.selectedOrder.summaryText)
          this.orderId = this.selectedOrder.id
          this.setOrderId(this.selectedOrder.id)
        } else {
          this.emptyOrder()
        }
      }
      this.setOrderLoading(false)
    },
    async submitOrder () {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      const threadId = this.threadId
      const orderId = this.orderId
      const purchases = orderId ? this.purchases : this.selectedOrder.purchases
      const setPurchases = orderId ? this.setPurchases : this.selectedOrder.setPurchases
      const checkpPurchases = purchases.find((x) => x.quantity > x.objectAvailable)
      const checkSetPurchases = setPurchases.find((x) => x.quantity > x.objectAvailable)
      if (checkpPurchases || checkSetPurchases) {
        this.overStock = true
      } else {
        this.overStock = false
      }

      if (!this.isSubmitting && (!this.overStock || this.preOrder)) {
        this.openLoading()
        let fullAddress = ''
        if (this.selectedOrder.address) {
          fullAddress += this.selectedOrder.address
        }
        if (this.selectedOrder.postcode) {
          fullAddress += ` ${this.selectedOrder.postcode}`
        }
        const customer = this.customers[customerId]
        let contactMethod = 'facebook'
        if (this.platform === 'instagram') {
          contactMethod = 'instagram'
        }
        const payload = {
          chat: {
            order: {
              id: this.selectedOrder.id,
              customer: customer.id,
              name: this.selectedOrder.name,
              address: fullAddress,
              tel: this.selectedOrder.tel,
              contactMethod,
              email: this.selectedOrder.email,
              annotation: this.selectedOrder.annotation,
              tag: customer.name,
              shippingType: this.selectedOrder.shippingType,
              shippingFee: Number(this.selectedOrder.shippingFee),
              preorder: this.preOrder,
              taxId: this.selectedOrder.taxId,
              taxable: this.taxAble,
              stockId: this.selectedOrder.stockId,
              promoCodeId: this.selectedOrder.promoCodeId,
              voucherId: this.selectedOrder.voucherId,
              promoCodeDiscount: this.selectedOrder.promoCodeDiscount,
              orderDiscount: Number(this.selectedOrder.orderDiscount),
              purchasesAttributes: purchases,
              setPurchasesAttributes: setPurchases,
              holding: this.selectedOrder.holding
            },
            page_id: pageId,
            threadId: customer.threadId,
            facebookUserId: customer.id
          }
        }

        const { result } = await OrderProvider.chatSubmitOrder({ pageId }, payload)
        if (result === 'error') {
          this.showErrorMessage({
            type: 'error',
            message: 'เกิดข้อผิดพลาด สินค้าบางรายการหมดสต็อก'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 5000)
        } else {
          this.closeLoading()
          this.showErrorMessage({
            type: 'success',
            message: 'บันทึกออเดอร์เรียบร้อยแล้ว'
          })
          setTimeout(() => {
            this.closeErrorMessage()
          }, 5000)
          this.loadAsyncOrder(pageId, threadId, customerId)
          this.newOrders = []
          this.isNewOrder = false
        }
        this.isSubmitting = false
      } else {
        this.showErrorMessage({
          type: 'error',
          message: 'ออเดอร์มีจำนวนสินค้าเกินสต็อก'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 5000)
      }
    }
  }
}
</script>

<style lang="scss">
  #chatbox-wrap {
    position: relative;
  }
  .chat-resizer {
    width: 2px;
    height: 100%;
    background: transparent;
    position: absolute;
    border-left: 1px solid #E4E4E4;
    right: -2px;
    top: 0;
    cursor: col-resize;
    z-index: 1;
  }
  .chat-resizer:hover {
    border-left: 3px solid var(--v-primary-base);
    right: -3px;
  }
  .message-wrap {
    border-left: 1px solid #E4E4E4;
    border-right: 1px solid #E4E4E4;
  }
  .order-wrap {
    position: relative;
    width: 400px;
    padding: 16px 8px 8px;
  }
  .order-wrap .order-main {
    max-height: calc(100vh - 286px);
    overflow-y: auto;
    padding-right: 5px;
  }
  .order-wrap .order-action {
    height: 36px;
    margin-top: 8px;
  }
  .message .v-input__prepend-inner {
    margin-top: 6px;
  }
  .message .v-input__append-inner {
    margin-top: 6px;
  }
</style>

<style scoped>
  .message {
    height: calc(100vh - 52px);
  }
</style>

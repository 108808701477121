<template>
  <div class="payment">
    <div
      class="order-main"
      :style="{'height': containerHeight, 'padding-bottom': paddingBottom}"
      @scroll="onScroll($event)">
      <div
        v-if="transferred && !paymentAccepted"
        class="text-right mt-2">
        <span class="red--text">
          รอการตรวจหลักฐานชำระเงิน
        </span>
      </div>
      <div class="mt-0">
        <label class="text--book"> หลักฐานการโอนเงิน </label>
        <div
          v-if="imageSlip || imageUpload"
          class="text-center mt-2">
          <img
            v-if="imageSlip"
            :src="imageSlip"
            alt="imageSlip"
            style="width: 80%;">
          <img
            v-else-if="imageUpload && !paymentAccepted "
            :src="imageUpload"
            alt="imageUpload"
            style="width: 80%;">
        </div>
        <v-text-field
          v-else-if="paymentAccepted"
          hide-details
          disabled
          filled
          dense>
        </v-text-field>
        <p
          v-else
          class="primary--text mt-2">
          กรุณาเลือกรูปภาพผ่านแชท
        </p>
      </div>
      <div class="mt-2">
        <label class="text--book"> วันที่โอน </label>
        <v-dialog
          ref="dialog"
          v-model="datePickerModal"
          width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateFormatted"
              :disabled="paymentAccepted ? true : false"
              prepend-inner-icon="mdi-calendar"
              v-bind="attrs"
              hide-details
              :outlined="paymentAccepted ? false : true"
              :filled="paymentAccepted ? true : false"
              dense
              v-on="on">
            </v-text-field>
          </template>
          <v-date-picker
            :value="transferredDateField"
            full-width
            @input="updatePayment('transferredDateField', $event)">
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="error"
              @click="datePickerModal = false">
              ยกเลิก
            </v-btn>
            <v-btn
              depressed
              color="primary"
              @click="datePickerModal = false">
              ตกลง
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>
      <div class="mt-2">
        <label class="text--book"> เวลาที่โอน </label>
        <v-text-field
          :value="transferredTimeField"
          :disabled="paymentAccepted ? true : false"
          type="time"
          prepend-inner-icon="mdi-clock"
          class="mt-2"
          hide-details
          :outlined="paymentAccepted ? false : true"
          :filled="paymentAccepted ? true : false"
          dense
          @input="updatePayment('transferredTimeField', $event)">
        </v-text-field>
      </div>
      <div class="mt-2">
        <label class="text--book"> ธนาคารของร้านค้า </label>
        <v-select
          :value="orderBank"
          :items="banks"
          :disabled="paymentAccepted ? true : false"
          item-text="name"
          item-value="value"
          class="mt-2"
          hide-details
          :outlined="paymentAccepted ? false : true"
          :filled="paymentAccepted ? true : false"
          dense
          @input="updatePayment('orderBank', $event)">
        </v-select>
      </div>
    </div>
    <div class="order-action">
      <v-btn
        v-if="!paymentAccepted"
        color="primary"
        depressed
        rounded
        @click="onSavePayment()">
        <img
          :src="saveButton"
          alt="saveButton"
          class="mr-2">
        บันทึก
      </v-btn>
    </div>
    <v-overlay
      :absolute="true"
      :opacity="0.08"
      :value="isLoading">
      <v-progress-circular
        :indeterminate="isLoading"
        color="primary">
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrderProvider from '@/resources/OrderProvider'

export default {
  name: 'Payment',
  props: {
    imageSlip: {
      type: String,
      default: ''
    },
    transferredDateField: {
      type: String,
      default: null
    },
    transferredTimeField: {
      type: String,
      default: null
    },
    orderBank: {
      type: String,
      default: ''
    },
    loadAsyncOrder: {
      type: Function,
      default: () => null
    },
    paymentAccepted: {
      type: Boolean,
      default: false
    },
    transferred: {
      type: Boolean,
      defaiult: false
    }
  },
  data () {
    return {
      scrollTop: 0,
      isSubmitting: false,
      datePickerModal: false,
      timePickerModal: false,
      saveButton: require('@/assets/svg/IconButton/SaveButton.svg')
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      shopInfo: 'Chat/shopInfo',
      orderId: 'Chat/orderId',
      getOrder: 'Chat/getOrder',
      isLoading: 'Notification/isLoading'
    }),
    containerHeight () {
      return `calc(100vh - ${ 343 - this.scrollTop }px)`
    },
    paddingBottom () {
      return this.scrollTop < 60 ? `${this.scrollTop}px` : '60px'
    },
    dateFormatted () {
      return this.$dayjs(this.transferredDateField, ['YYYY-MM-DD', 'DD-MM-YYYY']).format('DD-MM-YYYY')
    },
    bankAccounts () {
      return this.shopInfo.bankAccounts ? this.shopInfo.bankAccounts : []
    },
    banks () {
      return this.shopInfo.shop ? this.shopInfo.shop.banksWithName : []
    },
    imageUpload () {
      return this.getOrder(this.orderId).paymentSlip || null
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'Notification/showErrorMessage',
      closeErrorMessage: 'Notification/closeErrorMessage',
      openLoading: 'Notification/openLoading',
      closeLoading: 'Notification/closeLoading'
    }),
    async onSavePayment () {
      const pageId = this.pageId
      if (!this.isSubmitting) {
        this.isSubmitting = true
        this.openLoading()
        await OrderProvider.submitPayment({ pageId },
        {
          bank: this.orderBank,
          transferredAt: `${this.transferredDateField} ${this.transferredTimeField}`,
          orderId: this.orderId,
          transferNote: this.transferNote,
          paymentSlip: this.imageSlip ? this.imageSlip : this.imageUpload
        })
        this.showErrorMessage({
          type: 'success',
          message: 'บันทึกการชำระเงินเรียบร้อยแล้ว'
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 5000)
        this.loadAsyncOrder()
        this.isSubmitting = false
        this.closeLoading()
      }
    },
    updatePayment (name, val) {
      this.$emit(`update:${name}`, val)
    },
    onScroll (event) {
      const element = event.currentTarget || event.target
      this.scrollTop = element.scrollTop
      this.$emit('scroll', event)
    }
  }
}
</script>

<style>
  .payment .v-select__selection--comma {
    color: #6c6c6c !important;
  }
</style>

<template>
  <div class="quick-reply">
    <v-dialog
      :value="isQuickReplyMessage"
      max-width="700"
      @click:outside="closeQuickReplyMessage()"
      @keydown.esc="closeQuickReplyMessage()">
      <v-card>
        <div class="text-right">
          <v-btn
            icon
            @click="closeQuickReplyMessage()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div
            v-if="!isCreateReply"
            class="mb-3">
            <label> ข้อความที่ใช้บ่อย </label>
            <v-btn
              class="primary ml-4"
              icon
              x-small
              @click="newReply()">
              <v-icon
                color="white"
                small>
                mdi-plus
              </v-icon>
            </v-btn>
          </div>
          <div
            v-else
            class="d-flex align-center">
            <div
              style="cursor: pointer;"
              @click="isCreateReply = false">
              <v-icon
                color="primary">
                mdi-chevron-left
              </v-icon>
              <span class="primary--text"> กลับ </span>
            </div>
            <span class="mx-5"> | </span>
            <span> เพิ่มข้อความใช้บ่อย </span>
          </div>
          <div v-if="!isCreateReply">
            <v-text-field
              v-model="searchReply"
              append-icon="mdi-magnify"
              class="mb-3"
              placeholder="ค้นหา.."
              outlined
              dense
              hide-details>
            </v-text-field>
            <div style="height: 300px; overflow-y: scroll;">
              <v-list-item
                v-for="(item, index) in replyMessage"
                :key="item.id"
                style="border-bottom: 1px solid #E8E8E8;">
                <v-list-item-action v-if="!isEditReply">
                  <v-btn
                    icon
                    @click="sendReply(index)">
                    <v-icon style="transform: rotate(-45deg);">
                      mdi-send
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content v-if="isEditReply && indexEditReply === index">
                  <v-textarea
                    v-model="selectedReply.message"
                    rows="5"
                    class="mt-5"
                    placeholder="พิมพ์ข้อความของคุณ..."
                    style="border: 1px solid #C9C9C9"
                    filled
                    no-resize
                    hide-details>
                  </v-textarea>
                  <div class="text-right mt-4 mb-4">
                    <v-btn
                      color="error"
                      outlined
                      @click="isEditReply = false">
                      ยกเลิก
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="ml-2"
                      rounded
                      depressed
                      @click="updateReply()">
                      <v-icon> mdi-content-save </v-icon>
                      <span> บันทึก </span>
                    </v-btn>
                  </div>
                </v-list-item-content>
                <v-list-item-content
                  v-else
                  class="d-flex justify-space-between"
                  @click="sendReply(index)">
                  <span> {{ item.message }} </span>
                </v-list-item-content>
                <v-list-item-action v-if="!isEditReply">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on">
                        <v-icon>
                          mdi-dots-vertical
                        </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="activeEditReply(index)">
                        <v-icon
                          color="primary">
                          mdi-square-edit-outline
                        </v-icon>
                        <span> แก้ไขข้อความ </span>
                      </v-list-item>
                      <v-list-item @click="deleteReply(index)">
                        <v-icon
                          color="error">
                          mdi-delete
                        </v-icon>
                        <span class="red--text"> ลบข้อความ </span>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </div>
          </div>
          <div v-else>
            <v-textarea
              v-model="selectedReply.message"
              rows="5"
              class="mt-5"
              placeholder="พิมพ์ข้อความของคุณ..."
              style="border: 1px solid #C9C9C9"
              filled
              no-resize
              hide-details>
            </v-textarea>
            <div class="text-right mt-4 mb-4">
              <v-btn
                color="error"
                outlined
                @click="isCreateReply = false">
                ยกเลิก
              </v-btn>
              <v-btn
                color="primary"
                class="ml-2"
                rounded
                depressed
                @click="updateReply()">
                <v-icon> mdi-content-save </v-icon>
                <span> บันทึก </span>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <snackbar
      :value="snackbarValue"
      :type="snackBarType"
      :message="snackbarMessage">
    </snackbar>

    <modal
      v-bind="confirmDialog"
      @onConfirm="dialogConfirm($event)"
      @onCancel="dialogCancel($event)" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ChatProvider from '@/resources/ChatProvider'
import ShopProvider from '@/resources/ShopProvider'

export default {
  name: 'QuickReplyMessageModal',
  props: {
    isQuickReplyMessage: {
      type: Boolean,
      require: true
    }
  },
  data () {
    return {
      snackbarValue: false,
      snackBarType: '',
      snackbarMessage: '',
      searchReply: '',
      isCreateReply: false,
      isEditReply: false,
      indexEditReply: 0,
      selectedReply: {},
      confirmDialog: {
        confirmType: null,
        confirmOnly: false,
        title: 'ลบข้อมูล',
        message: 'ยืนยันลบข้อความนี้หรือไม่',
        confirmText: 'ตกลง',
        cancelText: 'ยกเลิก',
        active: false,
        value: null
      }
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      shopInfo: 'Chat/shopInfo',
      customers: 'Chat/customers'
    }),
    customerName () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.name || ''
    },
    replyMessage () {
      if (this.searchReply) {
        return this.shopInfo.quickReplies.filter((item) => item.message.includes(this.searchReply))
      }
      return this.shopInfo.quickReplies
    }
  },
  methods: {
    ...mapActions({
      setShopInfo: 'Chat/setShopInfo',
      addNewMessage: 'Chat/addNewMessage'
    }),
    closeQuickReplyMessage () {
      this.$emit('update:isQuickReplyMessage', false)
    },
    activeEditReply (index) {
      this.indexEditReply = index
      this.isEditReply = true
      this.selectedReply = {
        id: this.shopInfo.quickReplies[index].id,
        message: this.shopInfo.quickReplies[index].message
      }
    },
    newReply () {
      this.selectedReply = {
        id: null,
        message: ''
      }
      this.isCreateReply = true
    },
    async updateReply () {
      const pageId = this.pageId
      await ChatProvider.updateQuickReply({ pageId }, {
        reply: this.selectedReply
      })
      this.loadShopInfo(pageId)
      this.selectedReply.message = ''
      this.snackbarValue = true
      this.snackBarType = 'success'
      this.snackbarMessage = 'ทำการเพิ่มข้อความใช้บ่อยเรียบร้อย'
      setTimeout(() => {
        this.snackbarValue = false
      }, 2000)
    },
    async deleteReply (index) {
      this.confirmDialog.active = true
      this.confirmDialog.value = index
    },
    async dialogConfirm () {
      const pageId = this.pageId
      await ChatProvider.deleteQuickReply({ pageId }, {
        reply: {
          id: this.shopInfo.quickReplies[this.confirmDialog.value].id,
          message: this.shopInfo.quickReplies[this.confirmDialog.value].message
        }
      })
      this.dialogCancel()
      this.loadShopInfo(pageId)
    },
    dialogCancel () {
      this.confirmDialog.active = false
      this.confirmDialog.value = null
    },
    async loadShopInfo (pageId) {
      try {
        const response = await ShopProvider.fetchShopInfo(pageId)
        this.setShopInfo(response)
      } catch (error) {
        console.error(error)
      }
    },
    async sendReply (index) {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      this.selectedReply.message = this.replyMessage[index].message
      const reply = {
        id: uuidv4(),
        for: customerId,
        result: {
          message: this.selectedReply.message,
          createdTime: this.$dayjs().format()
        },
        status: 'sending'
      }
      this.addNewMessage(reply)
      ChatProvider.sendMessage({
        pageId,
        customerId
      }, {
        message: this.selectedReply.message,
        customerName: this.customerName,
        customer: customerId,
        tid: reply.id
      }).then((res) => {
        this.addNewMessage({
          ...reply,
          mid: res.mid,
          error: res.error,
          status: res.status === 400 ? 'failed' : 'read'
        })
      })
      this.closeQuickReplyMessage()
    }
  }
}
</script>

<style>
  /* .v-text-field--enclosed .v-input__prepend-inner {
    margin-top: 6px;
  } */
  .v-list-item {
    cursor: pointer;
  }
</style>

<template>
  <div class="product">
    <div
      class="order-main"
      :style="{'height': containerHeight, 'padding-bottom': paddingBottom}"
      @scroll="onScroll($event)">
      <v-row>
        <v-col>
          <label class="text--book d-block mb-3">
            สต็อกสินค้า
          </label>
          <v-select
            v-model="selectedOrder.stockId"
            :items="stocks"
            :disabled="selectedOrder.closed"
            :outlined="!selectedOrder.closed"
            :filled="selectedOrder.closed"
            item-text="name"
            item-value="id"
            hide-details
            @change="setStockId($event)">
          </v-select>
        </v-col>
        <v-col>
          <v-checkbox
            :input-value="preOrder"
            :disabled="selectedOrder.closed"
            label="พรีออเดอร์"
            @click="setPreOrder(!preOrder)">
          </v-checkbox>
          <v-checkbox
            :input-value="taxAble"
            :disabled="selectedOrder.closed"
            label="ใบกำกับภาษี"
            @click="setTaxAble(!taxAble)">
          </v-checkbox>
        </v-col>
      </v-row>
      <hr>
      <div class="d-flex align-center mt-4">
        <label class="text--book"> สินค้าที่สั่ง </label>
        <template v-if="canEditProduct && !selectedOrder.closed">
          <v-btn
            v-if="inboxNewProductSelection"
            class="ml-4"
            color="primary"
            depressed
            rounded
            @click="$emit('click:select-product')">
            <img
              :src="plusButton"
              alt="plusButton"
              class="mr-1">
            เพิ่มสินค้า
          </v-btn>
          <v-btn
            v-else
            class="ml-4"
            color="primary"
            depressed
            rounded
            @click="isActiveProductDetail = true">
            <img
              :src="plusButton"
              alt="plusButton"
              class="mr-1">
            เพิ่มสินค้า
          </v-btn>
        </template>
      </div>
      <div v-if="checkEmptyProduct">
        <div class="empty-order">
          <img
            :src="emptyProduct"
            alt="emptyProduct"
            class="mb-2">
          <p class="grey--text lighten-1">
            ไม่มีสินค้าอยู่ในออเดอร์
          </p>
        </div>
      </div>
      <div v-else>
        <v-data-table
          v-if="selectedOrder.purchases && selectedOrder.purchases.length"
          :headers="headers"
          :items="selectedOrder.purchases"
          item-key="id"
          class="mb-4"
          disable-pagination
          fixed-header
          hide-default-footer>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.id"
                :class="item._destroy === 1 ? 'd-none' : ''">
                <td class="text--book text-center">
                  <img
                    v-if="item.thumb"
                    :src="item.thumb"
                    alt="รูปสินค้า"
                    style="width: 20px;">
                  <v-icon v-else>
                    mdi-image-off
                  </v-icon>
                </td>
                <td class="text--book">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        class="product-name"
                        v-on="on">
                        {{ item.name }}
                      </span>
                    </template>
                    <span> {{ item.name }} </span>
                  </v-tooltip>
                </td>
                <td>
                  <v-text-field
                    v-model="item.price"
                    :onkeypress="onlyKeyNumber"
                    :disabled="selectedOrder.closed || !canEditProduct"
                    :outlined="!selectedOrder.closed"
                    :filled="selectedOrder.closed"
                    class="input-number"
                    hide-details>
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="item.quantity"
                    :disabled="selectedOrder.closed || !canEditProduct"
                    :outlined="!selectedOrder.closed"
                    :filled="selectedOrder.closed"
                    :error="item.quantity > item.objectAvailable && !preOrder"
                    type="number"
                    class="input-number"
                    @input="checkQuantity()">
                  </v-text-field>
                  <span
                    v-if="item.quantity > item.objectAvailable && !preOrder"
                    class="error--text caption">
                    สูงสุด {{ item.objectAvailable }}
                  </span>
                </td>
                <td>
                  <v-btn
                    v-if="canEditProduct"
                    icon
                    @click="removeSelectedProduct(index)">
                    <v-icon color="#ACACAC">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <v-data-table
          v-if="selectedOrder.setPurchases && selectedOrder.setPurchases.length"
          :headers="headers"
          :items="selectedOrder.setPurchases"
          item-key="id"
          fixed-header
          hide-default-footer>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="item.id"
                :class="item._destroy === 1 ? 'd-none' : ''">
                <td class="text--book text-center">
                  <img
                    v-if="item.thumb"
                    :src="item.thumb"
                    alt="รูปสินค้า"
                    style="width: 20px;">
                  <v-icon v-else>
                    mdi-image-off
                  </v-icon>
                </td>
                <td class="text--book">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-bind="attrs"
                        class="product-name"
                        v-on="on">
                        {{ item.name }}
                      </span>
                    </template>
                    <span> {{ item.name }} </span>
                  </v-tooltip>
                </td>
                <td>
                  <v-text-field
                    v-model="item.price"
                    :onkeypress="onlyKeyNumber"
                    :disabled="selectedOrder.closed || !canEditProduct"
                    :outlined="!selectedOrder.closed"
                    :filled="selectedOrder.closed"
                    class="input-number"
                    hide-details>
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="item.quantity"
                    :disabled="selectedOrder.closed || !canEditProduct"
                    :outlined="!selectedOrder.closed"
                    :filled="selectedOrder.closed"
                    :error="item.quantity > item.objectAvailable && !preOrder"
                    type="number"
                    class="input-number"
                    hide-details
                    @input="checkQuantity()">
                  </v-text-field>
                  <span
                    v-if="item.quantity > item.objectAvailable && !preOrder"
                    class="error--text caption">
                    สูงสุด {{ item.objectAvailable }}
                  </span>
                </td>
                <td>
                  <v-btn
                    v-if="canEditProduct"
                    icon
                    @click="removeSelectedSetProduct(index)">
                    <v-icon color="#ACACAC">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </div>
      <div class="text--book my-2">
        <label> หมายเหตุ </label>
        <v-text-field
          v-model="selectedOrder.annotation"
          :disabled="selectedOrder.closed"
          :outlined="!selectedOrder.closed"
          :filled="selectedOrder.closed"
          class="mt-2"
          hide-details>
        </v-text-field>
      </div>
      <v-row no-gutters>
        <v-col
          cols="6"
          class="pr-2">
          <div class="text--book">
            <label class="mb-2 d-block"> ส่วนลดเพิ่มเติม </label>
            <v-text-field
              v-model="selectedOrder.orderDiscount"
              :onkeypress="onlyKeyNumber"
              :disabled="selectedOrder.closed"
              :outlined="!selectedOrder.closed"
              :filled="selectedOrder.closed"
              hide-details>
            </v-text-field>
          </div>
        </v-col>
        <v-col
          cols="6"
          class="pl-2">
          <div class="text--book">
            <label class="mb-2 d-block"> ส่วนลดจากรหัส </label>
            <div class="d-flex text-field-group-btn">
              <v-text-field
                v-model="selectedOrder.promoCodeDiscount"
                :outlined="!selectedOrder.closed"
                :filled="selectedOrder.closed"
                disabled
                hide-details>
              </v-text-field>
              <v-btn
                :disabled="selectedOrder.closed"
                color="primary"
                class="small-btn"
                outlined
                @click="isActivePromoCode = true">
                {{ selectedOrder.promoCode ? selectedOrder.promoCode.code : 'รหัสส่วนลด' }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="mt-2">
        <v-col
          cols="6"
          class="pr-2">
          <label class="text--book d-block mb-3"> การจัดส่ง </label>
          <v-select
            v-model="selectedOrder.shippingType"
            :items="shippingTypes"
            :disabled="selectedOrder.closed"
            :outlined="!selectedOrder.closed"
            :filled="selectedOrder.closed"
            item-text="name"
            item-value="id"
            hide-details>
          </v-select>
        </v-col>
        <v-col
          cols="6"
          class="pl-2">
          <label class="text--book d-block mb-3"> ค่าส่ง </label>
          <div class="d-flex text-field-group-btn">
            <v-text-field
              v-model="selectedOrder.shippingFee"
              :disabled="selectedOrder.closed"
              :outlined="!selectedOrder.closed"
              :filled="selectedOrder.closed"
              class="mb-2"
              hide-details>
            </v-text-field>
            <v-btn
              :disabled="selectedOrder.closed"
              class="small-btn mb-2"
              color="primary"
              outlined
              @click="calculate()">
              คำนวณ
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="mt-2">
        <label class="text--book"> ยอดรวม </label>
        <v-text-field
          :value="totalPrice"
          :outlined="!selectedOrder.closed"
          :filled="selectedOrder.closed"
          class="mt-2"
          hide-details
          disabled>
        </v-text-field>
      </div>
    </div>
    <div class="order-action">
      <div class="d-flex justify-space-between">
        <div>
          <v-btn
            v-if="!selectedOrder.slug"
            color="primary"
            class="white--text"
            depressed
            rounded
            @click="submitOrder()">
            <img
              :src="createButton"
              alt="createButton"
              class="mr-1">
            <span>
              สร้างออเดอร์
            </span>
          </v-btn>
          <v-btn
            v-else-if="!selectedOrder.closed"
            color="primary"
            class="white--text"
            depressed
            rounded
            @click="submitOrder()">
            <img
              :src="saveButton"
              alt="saveButton"
              class="mr-2">
            <span>
              บันทึก
            </span>
          </v-btn>
          <v-btn
            v-if="!selectedOrder.sendBill"
            class="ml-2"
            color="primary"
            outlined
            :disabled="!orderId"
            @click="sendMessage()">
            <img
              :src="orderId ? sendBillButton : sendBillDisable"
              alt="sendBillButton"
              class="mr-1">
            <span>
              ส่งบิล
            </span>
          </v-btn>
        </div>
        <v-spacer />
        <v-btn
          v-if="selectedOrder.slug"
          color="primary"
          outlined
          @click="printSelectedOrder()">
          <img
            :src="printButton"
            alt="printButton"
            class="mr-1">
          <span>
            พิมพ์
          </span>
        </v-btn>
      </div>
    </div>
    <v-overlay
      :absolute="true"
      :opacity="0.08"
      :value="isLoading">
      <v-progress-circular
        :indeterminate="isLoading"
        color="primary">
      </v-progress-circular>
    </v-overlay>
    <product-detail
      :order-id="orderId"
      :is-active-product-detail.sync="isActiveProductDetail"
      :is-type-product-detail="isTypeProductDetail"
      :data-combine="combine">
    </product-detail>
    <promo-code
      :is-active-promo-code.sync="isActivePromoCode"
      @onSelectCode="selectedCode($event)" />
    <send-bill :is-active-send-bill.sync="isActiveSendBill" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProductDetail from '@/views/inbox/pages/message/components/ProductDetail.vue'
import OrderProvider from '@/resources/OrderProvider'
import PromoCode from './PromoCode.vue'
import SendBill from './SendBill.vue'

export default {
  name: 'Product',
  components: {
    ProductDetail,
    SendBill,
    PromoCode
  },
  props: {
    orderId: {
      type: Number,
      default: 0
    },
    selectedOrder: {
      type: Object,
      default: () => ({
        purchases: [],
        setPurchases: []
      })
    },
    overStock: {
      type: Boolean,
      default: false
    },
    submitOrder: {
      type: Function,
      default: () => null
    }
  },
  data () {
    return {
      shipnityUrl: process.env.VUE_APP_SHIPNITY,
      scrollTop: 0,
      headers: [
        {
          text: 'รูป',
          value: 'thumb',
          sortable: false
        },
        {
          text: 'สินค้า',
          value: 'name',
          sortable: false
        },
        {
          text: 'ราคา',
          value: 'price',
          sortable: false,
          align: 'center'
        },
        {
          text: 'จำนวน',
          value: 'quantity',
          sortable: false,
          align: 'center'
        },
        {
          text: '',
          value: 'delete',
          sortable: false
        }
      ],
      total: 0,
      isActiveProductDetail: false,
      isTypeProductDetail: false,
      isActiveSendBill: false,
      isActivePromoCode: false,
      onlyKeyNumber: 'return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)',
      emptyProduct: require('@/assets/svg/EmptyProduct.svg'),
      createButton: require('@/assets/svg/IconButton/CreateButton.svg'),
      saveButton: require('@/assets/svg/IconButton/SaveButton.svg'),
      sendBillButton: require('@/assets/svg/IconButton/SendBillButton.svg'),
      printButton: require('@/assets/svg/IconButton/PrintButton.svg'),
      sendBillDisable: require('@/assets/svg/IconButton/SendBillDisable.svg'),
      plusButton: require('@/assets/svg/IconButton/PlusButton.svg')
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      shopInfo: 'Chat/shopInfo',
      preOrder: 'Chat/preOrder',
      taxAble: 'Chat/taxAble',
      orderClosed: 'Chat/orderClosed',
      sendBill: 'Chat/sendBill',
      stockId: 'Chat/stockId',
      getOrder: 'Chat/getOrder',
      isLoading: 'Notification/isLoading'
    }),
    combine () {
      const products = []
      if (this.selectedOrder.purchases && this.selectedOrder.purchases.length) {
        this.selectedOrder.purchases.forEach((item) => {
          products.push(item)
        })
      }
      if (this.selectedOrder.setPurchases && this.selectedOrder.setPurchases.length) {
        this.selectedOrder.setPurchases.forEach((item) => {
          products.push(item)
        })
      }
      return products
    },
    stocks () {
      return this.shopInfo.stocks
    },
    shippingTypes () {
      return this.shopInfo.shop ? this.shopInfo.shop.shippingTypesWithDropoff : []
    },
    inboxNewProductSelection () {
      return this.shopInfo.shop ? this.shopInfo.shop.inboxNewProductSelection : false
    },
    slug () {
      return this.getOrder(this.orderId).slug || []
    },
    purchases () {
      return this.getOrder(this.orderId).purchases || []
    },
    setPurchases () {
      return this.getOrder(this.orderId).setPurchases || []
    },
    shippingType () {
      return this.getOrder(this.orderId).shippingType || []
    },
    sumPrice () {
      let total = 0
      let purchasesLength = 0
      let setPurchasesLength = 0
      let purchasesData = []
      let setPurchasesData = []

      if (this.orderId) {
        purchasesLength = this.purchases.length
        purchasesData = this.purchases
      } else {
        purchasesLength = this.selectedOrder.purchases ? this.selectedOrder.purchases.length : 0
        purchasesData = this.selectedOrder.purchases
      }

      if (this.orderId) {
        setPurchasesLength = this.setPurchases.length
        setPurchasesData = this.setPurchases
      } else {
        setPurchasesLength = this.selectedOrder.setPurchases ? this.selectedOrder.setPurchases.length : 0
        setPurchasesData = this.selectedOrder.setPurchases
      }

      for (let i = 0; i < purchasesLength; i++) {
        total += purchasesData[i].price * purchasesData[i].quantity
      }

      for (let i = 0; i < setPurchasesLength; i++) {
        total += setPurchasesData[i].price * setPurchasesData[i].quantity
      }

      return total
    },
    calOrderDiscount () {
      return Number(this.sumPrice) - Number(this.selectedOrder.orderDiscount)
    },
    totalPrice () {
      let finalTotal = this.calOrderDiscount + Number(this.selectedOrder.shippingFee) - this.selectedOrder.promoCodeDiscount
      if (!finalTotal || finalTotal < 0) {
        finalTotal = 0
      }
      return finalTotal.toFixed(2)
    },
    checkEmptyProduct () {
      if (this.orderId) {
        const emptyPurchases = this.purchases.find((item) => item._destroy === 0)
        const emptySetPurchases = this.setPurchases.find((item) => item._destroy === 0)
        const emptySelectProduct = this.purchases.find((item) => !item.id)
        const emptySelectSetProduct = this.setPurchases.find((item) => !item.id)
        return !emptyPurchases && !emptySetPurchases && !emptySelectProduct && !emptySelectSetProduct
      }
      const purchases = this.selectedOrder.purchases
      const setPurchases = this.selectedOrder.setPurchases
      if (purchases && setPurchases) {
        return !purchases.length && !setPurchases.length
      }
      return null
    },
    canEditProduct () {
      return (!this.selectedOrder.transferred && !this.selectedOrder.closed)
      || (this.selectedOrder.transferred && this.shopInfo.shop.editProductAfterPay)
    },
    containerHeight () {
      return `calc(100vh - ${ 343 - this.scrollTop }px)`
    },
    paddingBottom () {
      return this.scrollTop < 60 ? `${this.scrollTop}px` : '60px'
    }
  },
  watch: {
    orderId (value) {
      if (value !== null) {
        const order = this.getOrder(value)
        this.$emit('update:selectedOrder', order)
      }
    }
  },
  mounted () {
    if (this.orderId !== null) {
      const order = this.getOrder(this.orderId)
      this.$emit('update:selectedOrder', order)
    }
    this.onLoadProduct = true
  },
  methods: {
    ...mapActions({
      setTab: 'Chat/setTab',
      setPreOrder: 'Chat/setPreOrder',
      setTaxAble: 'Chat/setTaxAble',
      setStockId: 'Chat/setStockId',
      deleteSelectedPurchases: 'Chat/deleteSelectedPurchases',
      deleteSelectedSetPurchases: 'Chat/deleteSelectedSetPurchases'
    }),
    onScroll (event) {
      const element = event.currentTarget || event.target
      this.scrollTop = element.scrollTop
      this.$emit('scroll', event)
    },
    checkQuantity () {
      const purchases = this.selectedOrder.purchases.find((i) => i.quantity > i.objectAvailable)
      const setPurchases = this.selectedOrder.setPurchases.find((i) => i.quantity > i.objectAvailable)
      if (purchases || setPurchases) {
        this.$emit('update:overStock', true)
      } else {
        this.$emit('update:overStock', false)
      }
    },
    selectedCode (selected) {
      if (selected.type === 'promo') {
        this.selectedOrder.voucherId = 0
        this.selectedOrder.promoCodeId = selected.item.id
        this.selectedOrder.promoCode = selected.item
      } else if (selected.type === 'voucher') {
        this.selectedOrder.voucherId = selected.item.id
        this.selectedOrder.promoCodeId = 0
        this.selectedOrder.promoCode = selected.item
      } else {
        this.selectedOrder.promoCodeId = 0
        this.selectedOrder.voucherId = 0
        this.selectedOrder.promoCode = null
      }
      this.selectedOrder.promoCodeDiscount = this.setDiscountFromPromoCode(selected)
    },
    setDiscountFromPromoCode (selected) {
      if (selected.type == null) return 0
      let sum = this.selectedOrder.purchases
        .filter((option) => option._destroy !== 1)
        .reduce((a, b) => a + (Number(b.price) || 0) * b.quantity, 0)
      sum += this.selectedOrder.setPurchases
        .filter((option) => option._destroy !== 1)
        .reduce((a, b) => a + (Number(b.price) || 0) * b.quantity, 0)
      return selected.item.discountType === 'baht'
        ? selected.item.discountValue
        : (sum - this.selectedOrder.orderDiscount) * (Number(selected.item.discountValue) / 100)
    },
    removeSelectedProduct (index) {
      if (this.orderId) {
        this.deleteSelectedPurchases({
          id: this.orderId,
          index
        })
      } else {
        this.selectedOrder.purchases.splice(index, 1)
      }
    },
    removeSelectedSetProduct (index) {
      if (this.orderId) {
        this.deleteSelectedSetPurchases({
          id: this.orderId,
          index
        })
      } else {
        this.selectedOrder.setPurchases.splice(index, 1)
      }
    },
    sendMessage () {
      this.isActiveSendBill = true
    },
    printSelectedOrder () {
      if (this.slug) {
        window.open(`${this.shipnityUrl}/orders?find_id=${this.slug}`)
      }
    },
    async calculate () {
      const pageId = this.pageId
      let purchases = this.selectedOrder.purchases
      let setPurchases = this.selectedOrder.setPurchases
      let shippingType = this.selectedOrder.shippingType
      const order = this.selectedOrder

      if (this.orderId) {
        purchases = this.purchases
        setPurchases = this.setPurchases
        shippingType = this.shippingType
      }

      const { result } = await OrderProvider.calculateShippingFee({ pageId }, {
        items: purchases,
        setItems: setPurchases
      })

      shippingType = shippingType.replace(' (Dropoff)', '')
      shippingType = shippingType.replace(' (ส่งผ่านระบบ)', '')

      const shipping = result.find((item) => item.name === shippingType)

      if (shipping) {
        order.shippingFee = Number(shipping.cost).toFixed(2)
        this.$emit('update:selectedOrder', order)
      } else {
        order.shippingFee = '0.00'
        this.$emit('update:selectedOrder', order)
      }
    }
  }
}
</script>

<style>
  .text-field-group-btn .v-text-field--outlined fieldset {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .text-field-group-btn .v-btn {
    min-height: 36px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .product .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
  }

  .product .v-input__slot {
    min-height: 36px !important;
  }

  .product .v-input__control {
    height: 36px;
  }

  .product .v-input__append-inner {
    margin-top: 6px;
  }

  .product .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 10px 6px;
    vertical-align: top;
  }

  .product hr {
    margin-top: 8px;
    border: 1px solid #E4E4E4;
  }

  .product .input-number input {
    text-align: right !important;
  }

  .product .v-select__selection--comma {
    color: #6c6c6c !important;
  }

  .product .v-data-table__wrapper {
    max-height: 350px;
  }

  .small-btn.v-btn:not(.v-btn--round).v-size--default {
    padding: 0 8px;
    font-size: 12px;
  }
</style>

<style scoped>
  .product-name {
    display: block;
    display: -webkit-box;
    max-width: 90%;
    line-height: 1.2;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
  }
  .empty-order {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
</style>

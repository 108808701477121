var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex ml-4",class:{ 'justify-end mr-4': _vm.sendFromPage }},[_c('div',{staticClass:"message-container",class:{ 'text-right': _vm.sendFromPage, 'text-left': !_vm.sendFromPage }},[_c('div',{staticClass:"message-body"},[(_vm.source.attachments)?_c('div',{staticClass:"message-image can-preview"},[_vm._l((_vm.source.attachments.data),function(attach,i){return [(attach.imageData)?_c('div',{key:i},[_c('v-img',{attrs:{"src":attach.imageData.previewUrl,"lazy-src":attach.imageData.previewUrl,"width":"100%","max-width":"320px","alt":"no image","contain":"","eager":""},on:{"click":function($event){return _vm.onPreview(attach.imageData.url)}}}),(!_vm.sendFromPage)?_c('div',[(_vm.tab === 2)?_c('v-btn',{staticClass:"message-btn",attrs:{"color":"#3a92b6","block":"","rounded":""},on:{"click":function($event){return _vm.useImage(attach)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v(" mdi-credit-card ")]),_c('span',{staticClass:"white--text"},[_vm._v(" ใช้เป็นหลักฐานการโอนเงิน ")])],1):_vm._e()],1):_vm._e()],1):_vm._e()]})],2):(_vm.source.sticker)?_c('div',{staticClass:"message-sticker"},[_c('img',{attrs:{"src":_vm.source.sticker}})]):(_vm.source.base64)?_c('div',{staticClass:"message-image"},[_c('img',{attrs:{"src":_vm.source.base64}})]):_vm._e(),(_vm.source.message)?_c('div',{staticClass:"message-text",class:{
            'primary': _vm.sendFromPage,
            'white--text': _vm.sendFromPage,
            'white': !_vm.sendFromPage,
            'chat-nodate': !_vm.showDate,
            'chat-nodate-first': _vm.conversationFirst,
            'chat-nodate-last': _vm.conversationLast,
          },domProps:{"innerHTML":_vm._s(_vm.parseTextToUrls(_vm.source.message))}}):_vm._e()]),_c('div',{class:{ 'text-right': _vm.sendFromPage }},[(_vm.source.status === 'sending')?_c('p',{staticClass:"caption text--book"},[_vm._v(" sending... ")]):(_vm.source.status === 'failed')?_c('p',{staticClass:"caption error--text"},[(_vm.source.error)?_c('v-tooltip',{attrs:{"max-width":"320","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"color":"error","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,598383930)},[_c('span',[_vm._v(" "+_vm._s(_vm.source.error)+" ")])]):_vm._e(),_vm._v(" ส่งข้อความล้มเหลว ")],1):(_vm.showDate)?_c('p',{staticClass:"caption text--book"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.source.createdTime))+"น. ")]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }
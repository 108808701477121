<template>
  <v-card
    class="product-item--rounded mb-3"
    outlined
    rounded>
    <v-card-text>
      <v-row dense>
        <v-col cols="3">
          รหัสสินค้า
        </v-col>
        <v-col cols="9">
          <div class="d-flex">
            <v-img
              v-if="itemImage"
              :src="itemImage"
              class="mr-3"
              max-width="80"
              height="auto"
            />
            <div>
              <v-tooltip
                :disabled="itemCode.length < 32"
                bottom>
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="text-overflow"
                    v-on="on">
                    {{ itemCode }}
                  </div>
                </template>
                {{ itemCode }}
              </v-tooltip>
              <v-tooltip
                :disabled="!itemDescriptionLong || itemDescriptionLong.length < 64"
                bottom>
                <template #activator="{ on, attrs }">
                  <div
                    v-bind="attrs"
                    class="product-caption--wrap text-overflow caption"
                    v-on="on">
                    {{ itemDescriptionLong ? stripHtml(itemDescriptionLong) : 'ไม่มีรายละเอียด' }}
                  </div>
                </template>
                <v-card
                  width="320"
                  color="transparent"
                  flat
                  dark>
                  {{ itemDescriptionLong ? stripHtml(itemDescriptionLong) : 'ไม่มีรายละเอียด' }}
                </v-card>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          ชื่อสินค้า
        </v-col>
        <v-col cols="9">
          <div>{{ itemName }}</div>
          <div class="d-flex flex-wrap">
            <template v-if="type === 'general'">
              <v-badge
                v-for="sub in itemSubproducts"
                :key="`${sub.id}`"
                :content="sub.countSelected"
                :value="sub.countSelected"
                color="primary"
                class="mr-3 my-1"
                overlap>
                <v-btn
                  :disabled="sub.objectAvailable < 1 && !preOrder"
                  color="primary"
                  outlined
                  small
                  @click="$emit('click:select', sub)">
                  {{ itemSubproducts.length === 1 ? "สินค้าหลัก" : sub.name }}
                  <span class="orange--text ml-1"> ({{ sub.objectAvailable }}) </span>
                </v-btn>
              </v-badge>
            </template>
            <template v-else-if="type === 'set'">
              <v-badge
                color="primary"
                class="mr-3 my-1"
                :content="itemCountSelected"
                :value="itemCountSelected"
                overlap>
                <v-btn
                  :disabled="itemObjectAvailable < 1 && !preOrder"
                  color="primary"
                  outlined
                  small
                  @click="$emit('click:select')">
                  สินค้าหลัก
                  <span class="orange--text ml-1"> ({{ itemObjectAvailable }}) </span>
                </v-btn>
              </v-badge>
            </template>
            <div v-else>
              ไม่มีตัวเลือก
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          คงเหลือ
        </v-col>
        <v-col cols="9">
          {{ itemObjectAvailable }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          ราคา
        </v-col>
        <v-col cols="9">
          {{ itemPrice | formatNumber }}
        </v-col>
      </v-row>
      <div class="d-flex mt-3">
        <v-btn
          :loading="detailSubmitting"
          :disabled="!itemDescriptionLong"
          class="mr-1"
          color="primary"
          depressed
          small
          @click="sendDetail(itemDescriptionLong)">
          <v-icon
            class="icon-send-rotate"
            left
            small>
            mdi-send
          </v-icon>
          รายละเอียด
        </v-btn>
        <v-btn
          v-if="type === 'general'"
          :loading="imageSubmitting"
          :disabled="!itemImage"
          color="primary"
          depressed
          small
          @click="sendImage(itemImage)">
          <v-icon
            class="icon-send-rotate"
            left
            small>
            mdi-send
          </v-icon>
          รูปภาพ
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'general'
    },
    itemImage: {
      type: [String, Number],
      default: undefined
    },
    itemCode: {
      type: [String, Number],
      default: undefined
    },
    itemName: {
      type: [String, Number],
      default: undefined
    },
    itemDescriptionLong: {
      type: [String, Number],
      default: undefined
    },
    itemSubproducts: {
      type: Array,
      default: () => ([])
    },
    itemObjectAvailable: {
      type: [String, Number],
      default: undefined
    },
    itemPrice: {
      type: [String, Number],
      default: undefined
    },
    itemCountSelected: {
      type: [String, Number],
      default: undefined
    },
    preOrder: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      detailSubmitting: false,
      imageSubmitting: false
    }
  },
  methods: {
    sendDetail (value) {
      this.detailSubmitting = true
      this.$emit('click:senddetail', this.stripHtml(value))
      setTimeout(() => {
        this.detailSubmitting = false
      }, 800)
    },
    sendImage (value) {
      this.imageSubmitting = true
      this.$emit('click:sendimage', value)
      setTimeout(() => {
        this.imageSubmitting = false
      }, 1600)
    },
    stripHtml (html) {
      const div = document.createElement('DIV')
      div.innerHTML = html
      return div.textContent || div.innerText || ''
    }
  }
}
</script>

<style scoped>
  .product-item--rounded {
    border-radius: 14px !important;
  }
  .product-caption--wrap {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
  }
  .icon-send-rotate {
    margin-top: -5px;
    transform: rotate(-45deg);
  }
</style>

<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
    @input="changeDialog($event)">
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        :ripple="false"
        class="mx-0 px-0"
        color="primary"
        min-width="42px"
        width="42px"
        outlined
        v-on="on">
        <v-icon>
          mdi-broadcast
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <div>ข้อความบรอดแคสต์</div>
        <v-spacer />
        <v-btn
          x-small
          icon
          @click="setDialog(false)">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form>
          <div class="broadcast__card mb-3">
            <SntUpload
              :value="form.pictureUrl"
              :size="30720"
              :confirm="false"
              class="broadcast__image mb-3"
              name="upload-picture-broadcast"
              height="250px"
              @remove="removePicture()"
              @submit="uploadPicture($event)" />
            <v-text-field
              v-model="form.message"
              :rules="messageRules"
              class="font-weight-bold pt-0 mt-0"
              @input="setDirty(true)" />
            <div class="broadcast__desc mb-3">
              คุณอาจได้รับข้อความเพิ่มเติมเกี่ยวกับหัวข้อนี้ตลอด 6 เดือนข้างหน้า
            </div>
            <v-btn
              color="#fbfbfb"
              class="broadcast__btn font-weight-bold"
              depressed
              block>
              {{ broadcastBtnName }}
            </v-btn>
          </div>
          <div class="d-flex align-center gap-3 mb-3">
            <div class="font-weight-medium">
              รอบ:
            </div>
            <div :style="{ width: '300px' }">
              <v-select
                v-model="form.frequency"
                :items="frequencyItems"
                placeholder="ทุกกี่วัน"
                item-value="value"
                item-text="text"
                hide-details
                outlined
                dense
                @input="setDirty(true)" />
            </div>
            <v-spacer />
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <a
          v-if="dirty"
          class="text-body-2 primary--text"
          @click="reset()">
          คืนค่า
        </a>
        <div
          v-else-if="!canSend"
          class="caption error--text">
          *จำเป็นต้องมีรูปภาพเท่านั้น
        </div>
        <v-spacer />
        <v-btn
          v-if="dirty"
          :ripple="false"
          :loading="submitting"
          class="px-4"
          color="primary"
          depressed
          rounded
          @click="save()">
          บันทึก
        </v-btn>
        <v-btn
          v-else
          :disabled="!canSend"
          :ripple="false"
          class="pr-3"
          color="primary"
          depressed
          rounded
          @click="send()">
          <v-icon
            class="icon-send-rotate"
            small
            left>
            mdi-send
          </v-icon>
          ส่งข้อความ
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ChatProvider from '@/resources/ChatProvider'
import SntUpload from '@/components/Form/SntUpload.vue'

export default {
  name: 'BroadcastMessageModal',
  components: {
    SntUpload
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      dirty: false,
      frequencyItems: [
        {
          text: 'ทุกวัน',
          value: 'daily'
        },
        {
          text: 'ทุกอาทิตย์',
          value: 'weekly'
        },
        {
          text: 'ทุกเดือน',
          value: 'monthly'
        }
      ],
      form: {
        pictureUrl: '',
        picture: null,
        frequency: 'daily',
        message: 'Untitled'
      },
      messageRules: [
        (v) => !!v || 'กรอกข้อความ',
        (v) => !v || v.length <= 20 || 'ความยาวไม่เกิน 20 ตัวอักษร'
      ]
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      customers: 'Chat/customers'
    }),
    canSend () {
      return !!this.form.pictureUrl
    },
    customerName () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.name || ''
    },
    btnName () {
      switch (this.form.frequency) {
        case 'daily': return 'daily'
        case 'weekly': return 'weekly'
        case 'monthly': return 'monthly'
        default: return ''
      }
    },
    broadcastBtnName () {
      switch (this.form.frequency) {
        case 'daily': return 'รับข้อความทุกวัน'
        case 'weekly': return 'รับข้อความทุกอาทิตย์'
        case 'monthly': return 'รับข้อความทุกเดือน'
        default: return ''
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    ...mapActions({
      addNewMessage: 'Chat/addNewMessage'
    }),
    removePicture () {
      this.form.picture = null
      this.form.pictureUrl = null
      this.$emit('update:product-picture', null)
      this.$emit('update:product-picture-url', null)
      this.setDirty(true)
    },
    uploadPicture (file) {
      this.form.picture = file.file
      this.form.pictureUrl = file.url
      this.setDirty(true)
    },
    async loadData () {
      const res = await ChatProvider.getBroadcast({ pageId: this.pageId })
      const data = res.results
      this.form = {
        pictureUrl: data.imageLink,
        picture: null,
        frequency: data.frequency,
        message: data.title
      }
    },
    changeDialog (dialog = false) {
      if (dialog) {
        this.loadData()
      }
    },
    reset () {
      this.loadData()
      this.setDirty(false)
    },
    async save () {
      this.submitting = true
      await ChatProvider.updateBroadcastWithFile({ pageId: this.pageId }, {
        frequency: this.form.frequency,
        title: this.form.message,
        image: this.form.picture
      }).finally(() => {
        this.submitting = false
      })
      this.setDirty(false)
    },
    async send () {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      const reply = {
        id: uuidv4(),
        for: customerId,
        result: {
          message: this.form.message,
          createdTime: this.$dayjs().format()
        },
        status: 'read'
      }
      this.addNewMessage(reply)

      ChatProvider.sendBroadcast({
        pageId,
        customerId
      }, {
        frequency: this.form.frequency,
        title: this.form.message,
        customerName: this.customerName

        // tid: reply.id
      }).then(() => {
        // console.log('res', res)
        // this.addNewMessage({
        //   ...reply,
        //   mid: res.mid,
        //   error: res.error,
        //   status: res.status === 400 ? 'failed' : 'read'
        // })
      })
      this.setDialog(false)
    },
    setDirty (dirty) {
      this.dirty = dirty
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>

<style scoped>
  .icon-send-rotate {
    margin-top: -4px;
    transform: rotate(-45deg);
  }
  .broadcast__card {
    background: #f1f1f1;
    border-radius: 12px;
    padding: 0 16px 16px 16px;
  }
  .broadcast__image {
    background: #f1f1f1;
  }
  .broadcast__desc {
    color: #8b8b8b;
  }
  .broadcast__btn {
    border-radius: 12px;
    text-transform: unset;
  }
</style>

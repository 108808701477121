<template>
  <div class="promocode">
    <v-dialog
      v-model="isOpen"
      max-width="960"
      persistent>
      <v-card>
        <div class="text-right">
          <v-btn
            icon
            @click:outside="closeCode()"
            @click="closeCode()">
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <div class="text-center">
            <v-btn
              outlined
              :color="'red'"
              @click="selectCode(null)">
              ไม่ใช้รหัสส่วนลด
            </v-btn>
          </div>
          <v-tabs v-model="tab">
            <v-tab>
              โค้ดส่วนลด
            </v-tab>
            <v-tab>
              โค้ดส่วนลดใช้ทันที
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-text-field
                v-model="voucherSearch"
                outlined
                dense
                hide-details
                placeholder="ค้นหา.."
                append-icon="mdi-magnify"
                class="my-3"
                @keyup.enter="getClaimedVouchers()"
                @input="setVoucherSearch()">
              </v-text-field>
              <v-data-table
                :items="claimedVouchers"
                loader-height="2"
                :headers="headers"
                :loading="isVoucherLoading"
                fixed-header
                hide-default-footer>
                <template v-slot:[`item.code`]="{ item }">
                  {{ item.promoCode.code }}
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  {{ item.promoCode.discountValue }} <span v-if="item.promoCode.discountType === 'baht'"> บาท </span>
                  <span v-else-if="item.promoCode.discountType === 'percent'"> % </span>
                </template>
                <template v-slot:[`item.condition`]="{ item }">
                  เมื่อซื้อ
                  <span v-if="item.promoCode.minRedeemType === 'count_value'"> {{ item.promoCode.minRedeemValue }} บาท </span>
                  <span v-else> {{ $_.floor(item.promoCode.minRedeemValue) }} ชิ้น </span> ขึ้นไป
                </template>
                <template v-slot:[`item.expire`]="{ item }">
                  {{ item.promoCode.endDate | formatTime }}
                </template>
                <template v-slot:[`item.data-action`]="{ item }">
                  <v-btn
                    color="primary"
                    outlined
                    @click="selectCode(item.promoCode, 'voucher')">
                    ใช้ส่วนลด
                  </v-btn>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-text-field
                v-model="promoSearch"
                outlined
                dense
                hide-details
                placeholder="ค้นหา.."
                append-icon="mdi-magnify"
                class="my-3"
                @keyup.enter="getPromoCodes()"
                @input="setPromoSearch()">
              </v-text-field>
              <v-data-table
                :items="promoCodes"
                loader-height="2"
                :headers="headers"
                :loading="isPromoLoading"
                fixed-header
                hide-default-footer>
                <template v-slot:[`item.code`]="{ item }">
                  {{ item.code }}
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  {{ item.discountValue }} <span v-if="item.discountType === 'baht'"> บาท </span>
                  <span v-else-if="item.discountType === 'percent'"> % </span>
                </template>
                <template v-slot:[`item.condition`]="{ item }">
                  เมื่อซื้อ
                  <span v-if="item.minRedeemType === 'count_value'"> {{ item.minRedeemValue }} บาท </span>
                  <span v-else> {{ $_.floor(item.minRedeemValue) }} ชิ้น </span> ขึ้นไป
                </template>
                <template v-slot:[`item.expire`]="{ item }">
                  {{ item.endDate | formatTime }}
                </template>
                <template v-slot:[`item.data-action`]="{ item }">
                  <v-btn
                    color="primary"
                    outlined
                    @click="selectCode(item, 'promo')">
                    ใช้ส่วนลด
                  </v-btn>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PromoProvider from '@/resources/PromoProvider'
import _ from 'lodash'

export default {
  name: 'PromoCode',
  props: {
    isActivePromoCode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOpen: false,
      headers: [
        { text: 'รหัส', value: 'code', width: '100', sortable: false },
        { text: 'ส่วนลด', value: 'price', width: '100', sortable: false },
        { text: 'เงื่อนไข', value: 'condition', width: '100', sortable: false },
        { text: 'ใช้ได้ถึง', value: 'expire', width: '100', sortable: false },
        { text: '', value: 'data-action', width: '100', sortable: false }
      ],
      isPromoLoading: false,
      promoCodes: [],
      promoSearch: '',
      promoLength: 0,
      isVoucherLoading: false,
      claimedVouchers: [],
      voucherSearch: '',
      voucherLength: 0,
      tab: null
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      customers: 'Chat/customers'
    }),
    threadId () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.threadId || null
    }
  },
  watch: {
    isActivePromoCode (e) {
      this.isOpen = e
      if (this.isOpen) {
        this.getClaimedVouchers()
        this.getPromoCodes()
      }
    }
  },
  mounted () {
    this.isOpen = this.isActivePromoCode
    this.getClaimedVouchers()
    this.getPromoCodes()
  },
  methods: {
    ...mapActions({
      setPromoCode: 'Chat/setPromoCode',
      setSelectPromoCode: 'Chat/setSelectPromoCode'
    }),
    setVoucherSearch: _.debounce(function () {
      this.getClaimedVouchers()
    }, 800),
    async getClaimedVouchers () {
      const pageId = this.pageId
      const threadId = this.threadId
      this.isVoucherLoading = true
      const response = await PromoProvider.fetchPromoCode({
        pageId,
        threadId
      }, {
        search: this.voucherSearch
      }).finally(() => {
        this.isVoucherLoading = false
      })
      this.claimedVouchers = response.claimedVouchers
    },
    setPromoSearch: _.debounce(function () {
      this.getPromoCodes()
    }, 800),
    async getPromoCodes () {
      const pageId = this.pageId
      const threadId = this.threadId
      this.isPromoLoading = true
      const response = await PromoProvider.fetchPromoCode({
        pageId,
        threadId
      }, {
        search: this.promoSearch
      }).finally(() => {
        this.isPromoLoading = false
      })
      this.promoCodes = response.promoCodes
    },
    selectCode (item, type) {
      this.$emit('onSelectCode', {
        item,
        type
      })
      this.closeCode()
    },
    closeCode () {
      this.isOpen = false
      this.$emit('update:isActivePromoCode', false)
    }
  }
}
</script>

<style scoped>
</style>

import HttpRequest from './HttpRequest'

class OrderProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_API)
  }

  fetchOrder = ({ pageId, threadId }, payload, config) => this.get(
    `/pages/${pageId}/chats/${threadId}/orders.json`,
    { payload },
    config
  ).catch((err) => this.handleError(err))

  chatSubmitOrder = ({ pageId }, payload, config) => this.post(
    `/pages/${pageId}/chats/create_order`,
    { payload },
    config
  ).catch((err) => this.handleError(err))

  chatSubmitClose = ({ pageId }, payload, config) => this.post(
    `/pages/${pageId}/chats/submit_close`,
    { payload },
    config
  ).catch((err) => this.handleError(err))

  calculateShippingFee = ({ pageId }, payload, config) => this.post(
    `/pages/${pageId}/chats/calculate_shipping_fee`,
    { payload },
    config
  ).catch((err) => this.handleError(err))

  submitPayment = ({ pageId }, payload, config) => this.post(
    `/pages/${pageId}/chats/submit_payment`,
    { payload },
    config
  ).catch((err) => this.handleError(err))

  deleteOrder = ({ pageId }, payload, config) => this.delete(
  `/pages/${pageId}/chats/delete_order`,
  { payload },
    config
  ).catch((err) => this.handleError(err))
}

export default new OrderProvider()

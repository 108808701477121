<template>
  <v-dialog
    :value="isLinkRewardMessage"
    width="520"
    @click:outside="close()"
    @keydown.esc="close()">
    <v-card>
      <div class="text-right">
        <v-btn
          icon
          @click="close()">
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <div
          class="d-flex align-center">
          <span> ลิ้งค์เก็บโค้ด </span>
        </div>
        <div>
          <v-textarea
            v-model="message"
            rows="5"
            class="mt-5"
            placeholder="พิมพ์ลิ้งค์ของคุณ..."
            style="border: 1px solid #C9C9C9"
            filled
            no-resize
            hide-details>
          </v-textarea>
          <div class="text-right mt-4 mb-4">
            <v-btn
              color="error"
              outlined
              @click="close()">
              ยกเลิก
            </v-btn>
            <v-btn
              color="primary"
              class="ml-2"
              rounded
              depressed
              @click="sendReply()">
              <v-icon
                class="mr-2"
                style="transform: rotate(-45deg); "
                small>
                mdi-send
              </v-icon>
              <span> ส่งข้อความ </span>
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import ChatProvider from '@/resources/ChatProvider'

export default {
  name: 'LinkRewardMessage',
  props: {
    isLinkRewardMessage: {
      type: Boolean,
      require: true
    }
  },
  data () {
    return {
      message: ''
    }
  },
  computed: {
    ...mapGetters({
      pageId: 'Chat/pageId',
      shopInfo: 'Chat/shopInfo',
      customers: 'Chat/customers'
    }),
    customerName () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.name || ''
    },
    rewardLink () {
      const customer = this.customers[this.$route.params.customerId] || {}
      return customer.rewardLink || ''
    }
  },
  watch: {
    isLinkRewardMessage (value) {
      if (value) { this.message = this.rewardLink }
    }
  },
  mounted () {
    this.message = this.rewardLink
  },
  methods: {
    ...mapActions({
      addNewMessage: 'Chat/addNewMessage'
    }),
    close () {
      this.$emit('update:isLinkRewardMessage', false)
    },
    async sendReply () {
      const pageId = this.pageId
      const customerId = this.$route.params.customerId
      const reply = {
        id: uuidv4(),
        for: customerId,
        result: {
          message: this.message,
          createdTime: this.$dayjs().format()
        },
        status: 'sending'
      }
      this.addNewMessage(reply)
      ChatProvider.sendMessage({
        pageId,
        customerId
      }, {
        message: reply.result.message,
        customerName: this.customerName,
        customer: customerId,
        tid: reply.id
      }).then((res) => {
        this.addNewMessage({
          ...reply,
          mid: res.mid,
          error: res.error,
          status: res.status === 400 ? 'failed' : 'read'
        })
      })
      this.close()
    }
  }
}
</script>

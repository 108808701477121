<template>
  <div class="chat-room-header">
    <div class="d-flex">
      <v-list-item class="px-0">
        <v-list-item-avatar size="60">
          <div
            :class="{ 'user-avatar-badge__frame--optin': optedIn }"
            class="user-avatar-badge__frame">
            <v-img
              :src="url"
              lazy-src="/imgs/avatar.png"
              @error="getFallback()">
            </v-img>
          </div>
        </v-list-item-avatar>
        <div class="mt-4 w-100">
          <div
            class="d-flex justify-space-between">
            <span class="text--book title-name">{{ customerName }}</span>
          </div>
          <div class="d-flex justify-space-between">
            <tag-selection />
            <admin-list />
          </div>
        </div>
      </v-list-item>
      <v-btn
        class="d-md-none d-block"
        icon
        x-small
        to="/">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TagSelection from './TagSelection.vue'
import AdminList from './AdminList.vue'
import avatar from '@/assets/imgs/avatar.png'
import ChatProvider from '@/resources/ChatProvider'

export default {
  name: 'ChatHeader',
  components: {
    TagSelection,
    AdminList
  },
  data () {
    return {
      url: null
    }
  },
  computed: {
    ...mapGetters({
      page: 'Chat/page',
      pageId: 'Chat/pageId',
      platform: 'Chat/platform',
      customers: 'Chat/customers'
    }),
    customer () {
      return this.customers[this.$route.params.customerId] || {}
    },
    customerName () {
      return this.customer.name || ''
    },
    optedIn () {
      return this.customer.optedIn
    }
  },
  watch: {
    platform: {
      immediate: true,
      handler (platform) {
        const customerId = this.$route.params.customerId
        const accessToken = this.page.accessToken
        if (platform === 'instagram') {
          this.url = avatar
          // this.getAvatar(this.customerName)
          //   .then((res) => {
          //     this.url = res ? res.result : avatar
          //   })
        } else if (platform === 'messenger') {
          this.url = `https://graph.facebook.com/${customerId}/picture?type=small&access_token=${accessToken}`
        }
      }
    }
  },
  methods: {
    getAvatar (username) {
      try {
        return ChatProvider.getInstagramAvatar({ pageId: this.pageId, username })
      } catch {
        return null
      }
    },
    getFallback () {
      this.url = avatar
    }
  }
}

</script>
<style scoped>
  .title-name {
    min-height: 25px;
  }
</style>
<style>
  /* .chat-room-header .v-input__slot {
    width: 50%;
    min-height: 35px !important;
  } */

  .chat-room .v-input__prepend-inner {
    margin-top: 6px;
  }

  .chat-room .v-btn:not(.v-btn--round).v-size--default {
    padding: 0px 10px;
    min-width: 0px;
  }

  .v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none;
  }

  .chat-room .v-input__append-inner {
    margin-top: 6px;
  }
</style>

<style scoped>
  .chat-room {
    border: 1px solid #E4E4E4;
  }

  .chat-room p {
    margin-bottom: 5px;
  }

  .chat-room-header {
    padding: 12px;
    background-color: #ffffff;
    position: relative;
  }

  .chat-room-body {
    background-color: #f4f4f4;
  }

  hr {
    height: 36px;
  }

</style>

<template>
  <div>
    <template v-for="item in items">
      <product-selection-card
        :key="`product-item-${item.id}`"
        :page-id="pageId"
        :item-code="item.code"
        :item-image="item.image"
        :item-name="item.name"
        :item-description-long="item.descriptionLong"
        :item-subproducts="item.subproducts"
        :item-price="item.price"
        :item-object-available="item.objectAvailable"
        :pre-order="preOrder"
        type="general"
        @click:select="addProduct(item, $event)"
        @click:senddetail="$emit('click:senddetail', $event)"
        @click:sendimage="$emit('click:sendimage', $event)" />
    </template>
    <infinite-loading
      :identifier="infiniteId"
      spinner="spiral"
      @infinite="infiniteHandler">
      <div
        slot="no-more"
        class="caption secondary--text">
        ไม่มีสินค้าเพิ่มเติม :)
      </div>
      <div
        slot="no-results"
        class="caption secondary--text">
        ไม่มีสินค้า :)
      </div>
    </infinite-loading>
  </div>
</template>

<script>
import _ from 'lodash'
import ProductProvider from '@/resources/ProductProvider'
import { mapActions } from 'vuex'
import ProductSelectionCard from './ProductSelectionCard.vue'

export default {
  components: { ProductSelectionCard },
  props: {
    pageId: {
      type: [String, Number],
      required: true
    },
    stockId: {
      type: [String, Number],
      required: true
    },
    preOrder: {
      type: Boolean,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    },
    selected: {
      type: Array,
      default: () => ([])
    },
    search: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      page: 1,
      products: [],
      infiniteId: +new Date()
    }
  },
  computed: {
    items () {
      return this.products.map((item) => ({
        ...item,
        subproducts: item.subproducts.map((sub) => {
          let count = 0
          this.selected.forEach((i) => {
            if (i.subproductId === sub.id) count += parseInt(i.quantity, 0)
          })
          return {
            ...sub,
            countSelected: count
          }
        })
      }))
    }
  },
  watch: {
    search () {
      this.page = 1
      this.products = []
      this.infiniteId += 1
    }
  },
  methods: {
    ...mapActions({
      addPurchases: 'Chat/addPurchases',
      setSelectedProduct: 'Chat/setSelectedProduct'
    }),
    addProduct (item, subproduct) {
      let fullName = item.name
      if (subproduct.name !== 'main') {
        fullName = `${item.name } (${subproduct.name})`
      }
      const selectedProduct = {
        thumb: item.imageThumb,
        name: fullName,
        price: Number(subproduct.objectPrice).toFixed(2),
        quantity: 1,
        subproductId: subproduct.id,
        objectAvailable: subproduct.objectAvailable
      }
      const product = this.selected.find((i) => i.subproductId === subproduct.id)
      if (this.preOrder || !product || (product && product.quantity < subproduct.objectAvailable)) {
        if (this.orderId) {
          this.addPurchases({
            id: this.orderId,
            product: selectedProduct
          })
        } else {
          this.setSelectedProduct(selectedProduct)
        }
      }
    },
    async getProducts ({
      page = 1,
      perPage = 7
    }) {
      const pageId = this.pageId
      const stockSelection = this.stockId
      this.loading = true
      const { results } = await ProductProvider.getProducts({ pageId }, {
        page,
        perPage,
        search: this.search,
        stockSelection
      }).finally(() => {
        this.loading = false
      })
      return results
    },
    infiniteHandler ($state) {
      this.getProducts({
        page: this.page
      }).then((products) => {
        if (products && products.length) {
          this.page += 1
          this.products = _.concat(this.products, products)
          $state.loaded()
        } else {
          $state.complete()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

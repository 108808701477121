import HttpRequest from './HttpRequest'

class ProductProvider extends HttpRequest {
  getProducts = ({ pageId }, payload, config) => this.get(
    `/pages/${pageId}/products/shipnity_products`,
    { payload },
    config
  ).catch((err) => this.handleError(err))
}

export default new ProductProvider()
